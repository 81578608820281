import { ModellingReport } from '../../../../../../api';
import { roundToLong, roundToShort, simplify } from '../../../../shared';
import { TooltipV3 } from '../../../../../../components/TooltipV3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import cn from 'classnames';

interface Props {
  data: ModellingReport;
  selectedImpactId?: string;
}

export const ByImpactContributionModellingTable = (props: Props) => {
  const impactsMatrix = props.data.calculations.product.impacts.map(({ id, name }) => ({
    id,
    name,
    product: props.data.calculations.product.analysis.impactStagesMatrix.find(({ impactId }) => impactId === id)!,
    model: props.data.calculations.model.analysis.impactStagesMatrix.find(({ impactId }) => impactId === id)!,
  }));

  return (
    <table className='w-full table-fixed rounded-xl overflow-hidden'>
      <thead className='text-xs text-zinc-500 uppercase border-t'>
        <tr className='text-sm h-10'>
          <th colSpan={4} />
          <th colSpan={8} className='text-center'>
            original
          </th>
          <th colSpan={8} className='text-center text-brandDark'>
            modelled
          </th>
        </tr>
        <tr className='h-10'>
          <th className='pl-3' colSpan={4}>
            impact category
          </th>
          <th colSpan={3} className='pl-3'>
            physical impact
          </th>
          <th colSpan={3}>final env. impact</th>
          <th colSpan={2} className='pl-3'>
            contribution
          </th>
          <th colSpan={3} className='pl-6 text-brandDark'>
            physical impact
          </th>
          <th colSpan={3} className='text-brandDark'>
            final env. impact
          </th>
          <th colSpan={2} className='pl-3 text-brandDark'>
            contribution
          </th>
        </tr>
      </thead>

      <tbody className='divide-y border-t text-sm'>
        {impactsMatrix.map(({ id, name, product, model }) => (
          <tr className={cn('h-10', props.selectedImpactId === id ? 'bg-brand text-white' : 'bg-white')} key={id}>
            <td colSpan={4} title={name} className='truncate pl-3'>
              {name}
            </td>
            <td colSpan={3} title={roundToLong(product.physicalValue)} className='pl-3'>
              {simplify(product.physicalValue)} {product.unit}
            </td>
            <td colSpan={3} className='truncate' title={`${roundToLong(product.impactPoints)} impact points`}>
              {simplify(product.impactPoints)} impact points
            </td>
            <td colSpan={2} title={roundToLong(product.absSharePercent)} className='pl-3'>
              <div className='grid grid-cols-2'>
                <div>{roundToShort(product.absSharePercent)}%</div>
                {product.isMajorImpact && (
                  <TooltipV3
                    disabled={!product.isMajorImpact}
                    content={
                      <div
                        className={cn(
                          'flex flex-col gap-3 rounded-lg font-normal text-xs text-zinc-800 bg-[#DACEFD] px-2 py-1 shadow-[0_0_4px_rgba(0,0,0,0.15)]',
                        )}
                      >
                        Most relevant
                      </div>
                    }
                  >
                    <span className='self-center justify-self-center'>
                      <FontAwesomeIcon className='text-lg text-amber-400' icon={solid('seal-exclamation')} />
                    </span>
                  </TooltipV3>
                )}
              </div>
            </td>

            <td
              colSpan={3}
              title={roundToLong(model.physicalValue)}
              className={cn('pl-6', props.selectedImpactId === id ? 'text-white' : 'text-brandDark')}
            >
              {simplify(model.physicalValue)} {model.unit}
            </td>
            <td
              colSpan={3}
              className={cn('truncate', props.selectedImpactId === id ? 'text-white' : 'text-brandDark')}
              title={`${roundToLong(model.impactPoints)} impact points`}
            >
              {roundToShort(model.impactPoints)} impact points
            </td>
            <td
              colSpan={2}
              title={roundToLong(model.absSharePercent)}
              className={cn('pl-3', props.selectedImpactId === id ? 'text-white' : 'text-brandDark')}
            >
              <div className='grid grid-cols-2'>
                <div>{roundToShort(model.absSharePercent)}%</div>
                {model.isMajorImpact && (
                  <TooltipV3
                    disabled={!model.isMajorImpact}
                    content={
                      <div
                        className={cn(
                          'flex flex-col gap-3 rounded-lg font-normal text-xs text-zinc-800 bg-[#DACEFD] px-2 py-1 shadow-[0_0_4px_rgba(0,0,0,0.15)]',
                        )}
                      >
                        Most relevant
                      </div>
                    }
                  >
                    <span className='self-center justify-self-center'>
                      <FontAwesomeIcon className='text-lg text-amber-400' icon={solid('seal-exclamation')} />
                    </span>
                  </TooltipV3>
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot className='text-sm border-t bg-slate-50'>
        <tr className='h-10 font-semibold'>
          <td colSpan={4} className='pl-3 text-brand'>
            Total
          </td>
          <td colSpan={3} />
          <td colSpan={3} className='truncate' title={`${roundToLong(props.data.calculations.product.impactPoints)} impact points`}>
            {simplify(props.data.calculations.product.impactPoints)} impact points
          </td>
          <td colSpan={2} className='pl-3'>
            100%
          </td>
          <td colSpan={3} />
          <td
            colSpan={3}
            className='truncate text-brandDark'
            title={`${roundToLong(props.data.calculations.model.impactPoints)} impact points`}
          >
            {simplify(props.data.calculations.model.impactPoints)} impact points
          </td>
          <td colSpan={2} className='pl-3 text-brandDark'>
            100%
          </td>
        </tr>
      </tfoot>
    </table>
  );
};
