import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { CSSProperties, Fragment, SetStateAction, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { ComparedProductReport, ComparePayload, compareProducts, getCompareCsvOutput } from '../../../../api/compare';
import { roundToLong, roundToShort, simplify } from '../../shared';
import {
  Amount,
  Component,
  CsvImpactId,
  CsvType,
  getModellingReportV3,
  getProductReport,
  ImpactId,
  Lens,
  LifecycleStageImpact,
  MatrixComponent,
  ProductStage,
  ProductType,
} from '../../../../api';
import { Collapse } from '../../../../components/Collapse';
import { ImpactValueType } from '../Report/Sku/Overview';
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { Menu } from '../../../../components/Menu';
import { MultiBarStackedChart } from '../Report/Modelling/components/MultiBarStackedChart';
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, Text, Tooltip } from 'recharts';
import { alignTickPosition } from '../../../../components/charts/shared';
import { ByStageContributionTable, fromStageImpactsComponent } from '../../../../components/ByStageContributionTable';
import { ModalV3 } from '../../../../components/ModalV3';
import { ModalFormApi } from '../../../../components/ModalForm';
import { GradeBadge } from '../../../../components/GradeBadge';
import { TooltipV3 } from '../../../../components/TooltipV3';
import { format } from 'date-fns';

enum View {
  Graph = 'Graph view',
  Table = 'Table view',
}

const overallImpact = { id: ImpactId.Overall, name: 'Total environmental impact' };

export const Compare = () => {
  let entities = [];
  const {
    pw1Id,
    p1Id,
    mw1Id,
    m1Id,
    pw2Id,
    p2Id,
    mw2Id,
    m2Id,
    pw3Id,
    p3Id,
    mw3Id,
    m3Id,
    pw4Id,
    p4Id,
    mw4Id,
    m4Id,
    pw5Id,
    p5Id,
    mw5Id,
    m5Id,
  } = useParams();

  entities.push(
    {
      pw1Id,
      p1Id,
      mw1Id,
      m1Id,
    },
    {
      pw2Id,
      p2Id,
      mw2Id,
      m2Id,
    },
    {
      pw3Id,
      p3Id,
      mw3Id,
      m3Id,
    },
    {
      pw4Id,
      p4Id,
      mw4Id,
      m4Id,
    },
    {
      pw5Id,
      p5Id,
      mw5Id,
      m5Id,
    },
  );

  const [selectedImpactValueType, setSelectedImpactValueType] = useState<ImpactValueType>(ImpactValueType.Points);
  const [impactByCategoryView, setImpactByCategoryView] = useState<View>(View.Graph);
  const [impactByLifecycleStageView, setImpactByLifecycleStageView] = useState<View>(View.Table);

  const comparePayload: ComparePayload = {
    products: entities
      .filter((entity) => Object.values(entity).some((value) => value))
      .map((entity) => ({
        workspaceSid: (entity as any)[Object.keys(entity)[0]],
        baseProductId: (entity as any)[Object.keys(entity)[1]],
        modelId: (entity as any)[Object.keys(entity)[3]] === '-' ? undefined : (entity as any)[Object.keys(entity)[3]],
      })),
  };

  const [data, setData] = useState<{ reports: ComparedProductReport[] }>();

  const [showImage, setShowImage] = useState(false);

  const [selectedImpact, setSelectedImpact] = useState(overallImpact);

  const [selectedReportUnit, setSelectedReportUnit] = useState<'kg' | 'serving' | 'sku'>('sku');
  const [expanded, setExpanded] = useState(new Array<string>());

  const [activateReference, setActivateReference] = useState(false);
  const [selectedReference, setSelectedReference] = useState<string>();
  const canShowCompareResult = activateReference && selectedReference !== undefined;
  const [waitingImpactByCategory, setWaitingImpactByCategory] = useState(false);
  const [waitingImpactByLifecycleStage, setWaitingImpactByLifecycleStage] = useState(false);
  const [waitingRelevantProcesses, setWaitingRelevantProcesses] = useState(false);

  const handleExpandedState = (id1: string) =>
    setExpanded((current) =>
      current.includes(id1) ? current.filter((id2) => !(id2.startsWith(id1) && id2.length >= id1.length)) : [...current, id1],
    );

  useEffect(() => {
    compareProducts(comparePayload, selectedReportUnit).call({
      ok: (data) => {
        setData(data);
      },
    });

    return () => {
      setData(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data) {
    return <div className='loading-skeleton h-full w-full'></div>;
  }

  const allImpactCategories: { id: ImpactId; name: string; unit?: string }[] = [
    overallImpact,
    ...data.reports[0].analysis.impactStagesMatrix.map(({ impactId, impactName, unit }) => ({
      id: impactId,
      name: impactName,
      unit: unit,
    })),
  ];

  const comparedWithReferenceResult = (origin: { id: string; value: number }, reference: { id: string; value: number }) => {
    if (origin.id === reference.id) {
      return <div className='text-zinc-500 bg-neutral-100 flex self-start rounded-full px-3 py-2 font-semibold leading-4'>Reference</div>;
    } else {
      const delta = origin.value - reference.value;
      if (Number(delta.toFixed(2)) < 0) {
        return (
          <div className='text-green-700 bg-green-50 flex self-start rounded-full px-3 py-2 font-semibold leading-4'>
            - {Math.abs(roundToShort((origin.value / reference.value) * 100 - 100))}%
          </div>
        );
      } else if (Number(delta.toFixed(2)) === 0) {
        return <div className='text-zinc-500 bg-neutral-100 flex self-start rounded-full px-3 py-2 font-semibold leading-4'>No change</div>;
      } else {
        return (
          <div className='text-red-500 bg-red-50 flex self-start  rounded-full px-3 py-2 font-semibold leading-4'>
            + {roundToShort((origin.value / reference.value) * 100 - 100)}%
          </div>
        );
      }
    }
  };

  const getReferenceProduct = (reports: ComparedProductReport[], reference: string) => {
    return reports.find((report) => report.product.id === reference);
  };

  return (
    <div className='relative -mx-6 px-6'>
      <Helmet title='Product comparison' />
      <div className='sticky top-0 z-30 flex flex-col justify-center items-center bg-white xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))]'>
        <div className='bg-white w-full flex justify-center'>
          <div className='flex justify-between items-center py-3 xl:px-12 w-full xl:w-[theme(screens.xl)]'>
            <div className='flex items-center gap-x-2'>
              <NavLink
                to='../'
                className='h-8 aspect-square flex items-center justify-center bg-[#E8EAF5] rounded-lg hover:bg-white hover:border-2 hover:border-brand'
              >
                <FontAwesomeIcon className='text-xl text-brand' icon={regular('chevron-left')} />
              </NavLink>
              <div className='text-xl font-semibold leading-snug'>Product comparison</div>
            </div>
            <div className='flex items-center gap-x-4'>
              <TooltipV3
                placement='bottom'
                content={
                  <div className='max-w-96 text-white text-xs leading-3 rounded-lg shadow px-2 py-1 bg-violet-950'>
                    In order to provide percentage differences, you need to select a reference product as the point of reference against
                    which the other products or versions are compared.
                  </div>
                }
              >
                <div className='border rounded-full px-2 py-1.5 flex h-full'>
                  <button
                    type='button'
                    className='flex items-center gap-2'
                    onClick={() => {
                      setActivateReference((currentState) => {
                        const nextState = !currentState;

                        if (nextState) {
                          setImpactByCategoryView(View.Table);
                          setImpactByLifecycleStageView(View.Table);
                        } else {
                          setSelectedReference(undefined);
                        }

                        return !currentState;
                      });
                    }}
                  >
                    <div className={cn('flex w-7 h-4 p-0.5 rounded-full', activateReference ? 'justify-end bg-brand' : 'bg-gray-300')}>
                      <div className='bg-white rounded-full w-3 aspect-square' />
                    </div>
                    <div className='text-sm'>Set a reference product</div>
                  </button>
                </div>
              </TooltipV3>
              <Menu
                placement='bottom-end'
                items={[
                  {
                    label: 'Per Unit',
                    onClick: () => {
                      setData(undefined);
                      compareProducts(comparePayload, 'sku').call({
                        ok: (data) => {
                          setData(data);
                        },
                      });
                      setSelectedReportUnit('sku');
                    },
                  },
                  {
                    label: 'Per Kg/L',
                    onClick: () => {
                      setData(undefined);
                      compareProducts(comparePayload, 'kg').call({
                        ok: (data) => {
                          setData(data);
                        },
                      });
                      setSelectedReportUnit('kg');
                    },
                  },
                  {
                    label: 'Per serving',
                    onClick: () => {
                      setData(undefined);
                      compareProducts(comparePayload, 'serving').call({
                        ok: (data) => {
                          setData(data);
                        },
                      });
                      setSelectedReportUnit('serving');
                    },
                    disabled: data.reports.some((report) => !report.product.servings),
                  },
                ]}
              >
                {() => (
                  <div className='flex flex-col'>
                    <button className='flex items-center border rounded-full px-2 py-1 h-full gap-x-2'>
                      <FontAwesomeIcon icon={light('scale-balanced')} />
                      {
                        {
                          kg: 'Per Kg/L',
                          serving: 'Per serving',
                          sku: 'Per Unit',
                        }[selectedReportUnit]
                      }
                      <FontAwesomeIcon className='text-xs' icon={light('chevron-down')} />
                    </button>
                  </div>
                )}
              </Menu>
            </div>
          </div>
        </div>
        <div className='w-full border-b border-zinc-200'></div>
      </div>
      <div className='bg-white xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))]'>
        <div className='flex flex-col items-center justify-center bg-slate-50'>
          <div className='flex justify-between items-center pt-6 px-12 w-full xl:w-[theme(screens.xl)]'>
            <div className='grid grid-cols-5 gap-x-6 w-full'>
              {data.reports.map((report, index) => (
                <div className='flex flex-col gap-y-4' key={index}>
                  <div className='flex items-center min-h-40'>
                    {report.product.imageUrl ? (
                      <div className='flex items-center justify-items-center'>
                        <img
                          onLoad={() => setShowImage(true)}
                          className={cn('flex flex-1 border rounded-xl max-w-40 max-h-40', {
                            invisible: !showImage,
                          })}
                          src={report.product.imageUrl}
                          alt='product'
                        />
                      </div>
                    ) : (
                      <div className='shrink-0 size-40 flex items-center justify-items-center'>
                        <FontAwesomeIcon className='text-neutral-300 size-16 mx-auto' icon={solid('box-circle-check')} />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='sticky top-[56px] z-20 bg-white xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))]'>
        <div className='flex flex-col items-center justify-center bg-slate-50'>
          <div className='flex justify-between items-center py-3 px-12 w-full xl:w-[theme(screens.xl)]'>
            <div className='grid grid-cols-5 w-full gap-x-6'>
              {data.reports.map((report, index) => (
                <div key={index} className='flex gap-x-2'>
                  {activateReference && (
                    <input
                      type='checkbox'
                      value={report.product.id}
                      className='flex self-start mt-1.5 shrink-0'
                      style={{ height: '1.25rem', width: '1.25rem' }}
                      checked={selectedReference === report.product.id}
                      onChange={(event) => {
                        if (selectedReference === event.target.value) {
                          setSelectedReference(undefined);
                        } else {
                          setSelectedReference(event.target.value);
                        }
                      }}
                    />
                  )}
                  <div title={report.product.name} className='text-xl font-semibold leading-snug line-clamp-2'>
                    {report.product.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))]'>
        <div className='flex flex-col items-center justify-center bg-slate-50'>
          <div className='flex justify-between items-center py-3 px-12 w-full xl:w-[theme(screens.xl)]'>
            <div className='grid grid-cols-5 gap-x-6 w-full'>
              {data.reports.map((report, index) => (
                <div key={index} className='grid grid-rows-2 gap-y-1'>
                  <div title={report.product.skuId} className='text-zinc-500 text-sm leading-tight truncate'>
                    Product ID: {report.product.skuId}
                  </div>
                  <div className='text-zinc-500 text-sm leading-tight'>
                    Product type:{' '}
                    {
                      {
                        [ProductType.Final]: 'Final',
                        [ProductType.Intermediate]: 'Intermediate',
                        [ProductType.Internal]: 'Internal',
                      }[report.product.productType]
                    }
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-col gap-y-6 px-6 pt-6'>
        <Collapse
          title={() => 'Overall impact'}
          body={
            <div className='grid grid-cols-5 gap-x-6 gap-y-20'>
              {data.reports.map((report, index) => (
                <div className='flex flex-col gap-y-2 justify-center pt-4' key={index}>
                  <div className='text-3xl'>{simplify(report.impactPoints)}</div>
                  <div className='text-zinc-500 tracking-wide uppercase text-xs'>impact points</div>
                  {canShowCompareResult &&
                    comparedWithReferenceResult(
                      { id: report.product.id, value: report.impactPoints },
                      {
                        id: getReferenceProduct(data.reports, selectedReference)!.product.id!,
                        value: getReferenceProduct(data.reports, selectedReference)!.impactPoints,
                      },
                    )}
                </div>
              ))}
            </div>
          }
        />
        {selectedReportUnit === 'kg' && (
          <Collapse
            title={() => 'Consumer rating'}
            body={
              <div className='grid grid-cols-5 gap-x-6 gap-y-20'>
                {data.reports.map((report, index) => (
                  <div key={index} className='flex pt-6'>
                    <GradeBadge grade={report.consumerView.overallGrade} />
                  </div>
                ))}
              </div>
            }
          />
        )}
        <Collapse
          title={() => 'Product description'}
          body={
            <div className='grid grid-cols-5 gap-x-6 gap-y-20'>
              {data.reports.map((report, index) => (
                <div className='flex flex-col gap-y-6 py-6' key={index}>
                  {[
                    {
                      name: 'Net amount',
                      value: report.product.amount.value + report.product.amount.unit.name,
                    },
                    {
                      name: 'Category',
                      value: report.product.category?.name ?? '-',
                    },
                    {
                      name: 'Conservation requirements',
                      value: report.product.conservation.requirement.name,
                    },
                    {
                      name: 'Status',
                      value: {
                        [ProductStage.Development]: 'Development',
                        [ProductStage.Production]: 'Production',
                      }[report.product.stage],
                    },
                    {
                      name: 'Analysed amount',
                      value: report.scope.scaledProductAmount.value + report.scope.scaledProductAmount.unit.name,
                    },
                    ...(() =>
                      report.scope.scaledServings
                        ? [
                            {
                              name: 'Analysed servings',
                              value: report.scope.scaledServings,
                            },
                          ]
                        : [])(),
                  ].map(({ name, value }, i) => (
                    <div key={i}>
                      <div className='font-semibold'>{name}</div>
                      <div>{value}</div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          }
        />
        <Collapse
          title={(opened: boolean) => (
            <div className='flex w-full items-center justify-between'>
              <div className='flex gap-x-1'>
                <div>Impact by category</div>
                {impactByCategoryView === View.Table && <div className='text-sm self-end pb-0.5'>(sorted by total contribution)</div>}
              </div>
              {opened && (
                <div className='flex items-center gap-x-2'>
                  <ViewToggle disabled={activateReference} view={impactByCategoryView} setView={setImpactByCategoryView} />
                  <button
                    disabled={waitingImpactByCategory}
                    onClick={() => {
                      setWaitingImpactByCategory(true);
                      getCompareCsvOutput<CsvType.impactByCategory>(
                        `Sustained Product Comparison - Impact by category (${selectedImpact.name}) ${format(new Date(), 'yyyy-MM-dd')}`,
                        comparePayload,
                        {
                          reportTarget: CsvType.impactByCategory,
                        },
                      ).call({
                        ok: () => {
                          setWaitingImpactByCategory(false);
                        },
                        fail: () => {
                          setWaitingImpactByCategory(false);
                        },
                      });
                    }}
                    className={cn(
                      { 'cursor-wait': waitingImpactByCategory },
                      'flex gap-2 items-center px-4 text-brandDarkPurple2 font-semibold text-base',
                    )}
                  >
                    <FontAwesomeIcon icon={solid('download')} />
                    Export as CSV
                  </button>
                </div>
              )}
            </div>
          )}
          body={
            impactByCategoryView === View.Table ? (
              <div className='grid grid-cols-5 gap-x-6 gap-y-20 py-6'>
                {data.reports.map((report, index) => (
                  <div className='grid auto-rows-auto gap-y-4' key={index}>
                    {report.analysis.impactStagesMatrix.map((impact, index) => (
                      <div
                        className={cn('flex flex-col p-3', canShowCompareResult ? 'h-44' : 'h-36', {
                          'bg-yellow-50': impact.isMajorImpact,
                        })}
                        key={index}
                      >
                        <div className='font-semibold flex justify-between items-start gap-x-2'>
                          <div>{impact.impactName}</div>
                          <FontAwesomeIcon
                            className={cn('text-xl text-amber-400', { hidden: !impact.isMajorImpact })}
                            icon={solid('seal-exclamation')}
                          />
                        </div>
                        <div className='flex items-center gap-x-1' title={roundToLong(impact.physicalValue)}>
                          <div className='text-2xl'>{simplify(impact.physicalValue)}</div>
                          <div className='leading-9 text-sm'>{impact.unit}</div>
                        </div>
                        <div className='flex items-center gap-x-1' title={roundToLong(impact.sharePercent)}>
                          <div className='text-xl'>{roundToShort(impact.sharePercent)}%</div>
                          <div className='text-sm'>of total contribution</div>
                        </div>
                        {canShowCompareResult && (
                          <div className='flex flex-col h-full justify-end'>
                            {comparedWithReferenceResult(
                              { id: report.product.id, value: impact.physicalValue },
                              {
                                id: getReferenceProduct(data.reports, selectedReference)!.product.id!,
                                value: getReferenceProduct(data.reports, selectedReference)!.analysis.impactStagesMatrix.find(
                                  (impact2) => impact2.impactId === impact.impactId,
                                )!.physicalValue,
                              },
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ) : (
              <div className='grid grid-cols-[1fr_2fr_1fr] justify-between gap-x-6 p-6'>
                {(() => {
                  const colors = ['#818CF8', '#E879F9', '#2DD4BF', '#60A5FA', '#FBBF24'];

                  const impacts = data.reports[0].analysis.impactStagesMatrix.map((impact) => ({
                    id: impact.impactId,
                    name: impact.impactName,
                  }));

                  const radarData = impacts.flatMap((impact1) => {
                    return {
                      ...impact1,
                      ...data.reports
                        .map((report, index) => {
                          const impact3 = report.analysis.impactStagesMatrix.find((impact2) => impact2.impactId === impact1.id)!;
                          return {
                            [`product${index}`]: {
                              name: report.product.name,
                              impactPoints: impact3.impactPoints,
                              absSharePercent: impact3.absSharePercent,
                              isMajor: impact3.isMajorImpact,
                            },
                          };
                        })
                        .reduce((acc, item) => {
                          const [key, value] = Object.entries(item)[0];
                          acc[key] = value;
                          return acc;
                        }, {}),
                    };
                  });

                  return (
                    <>
                      <div className='flex flex-col gap-y-2 truncate'>
                        {data.reports.map((report, index) => (
                          <div key={index} className='flex items-center gap-x-2'>
                            <div style={{ backgroundColor: colors[index] }} className='rounded-full size-2 shrink-0' />
                            <div title={report.product.name} className='truncate'>
                              {report.product.name}
                            </div>
                          </div>
                        ))}
                      </div>
                      {(() => {
                        const PolarChartCustomLabel = (props: {
                          payload: { coordinate: number; value: string };
                          x: number;
                          y: number;
                          textAnchor: 'start' | 'end' | 'middle';
                        }) => {
                          const shiftLabel = () => {
                            if (props.payload.coordinate > -110 && props.payload.coordinate < -70) {
                              return props.y + 10;
                            } else if (props.payload.coordinate > 89 && props.payload.coordinate < 91) {
                              return props.y + 12;
                            } else {
                              return props.y;
                            }
                          };

                          return (
                            <Text
                              x={
                                alignTickPosition({
                                  x: props.x,
                                  y: props.y,
                                  textAnchor: props.textAnchor,
                                  coordinate: props.payload.coordinate,
                                })!.x
                              }
                              y={
                                alignTickPosition({
                                  x: props.x,
                                  y: shiftLabel(),
                                  textAnchor: props.textAnchor,
                                  coordinate: props.payload.coordinate,
                                })!.y
                              }
                              style={{ fontSize: '12px' }}
                              textAnchor={props.textAnchor}
                              width={120}
                            >
                              {props.payload.value}
                            </Text>
                          );
                        };

                        return (
                          <RadarChart height={300} width={550} data={radarData} outerRadius='75%'>
                            <PolarGrid stroke='black' radialLines />
                            <PolarAngleAxis tick={PolarChartCustomLabel} dataKey='name' />
                            <PolarRadiusAxis
                              angle={90}
                              orientation='left'
                              tickFormatter={(value: number) => String(simplify(value))}
                              tickCount={7}
                              tick={{ fontSize: 12, fill: 'black' }}
                            />
                            {data.reports.map((_, index) => (
                              <Radar
                                key={index}
                                strokeWidth={2}
                                fillOpacity={0.3}
                                fill={colors[index]}
                                stroke={colors[index]}
                                isAnimationActive={false}
                                dataKey={`product${index}.impactPoints`}
                                dot={(props) => {
                                  return (
                                    <svg key={props.key} x={props.cx - 3} y={props.cy - 3} width={6} height={6}>
                                      <FontAwesomeIcon
                                        style={{ color: props.payload.payload.isMajor ? '#fbbf24' : props.stroke }}
                                        icon={solid('circle')}
                                      />
                                    </svg>
                                  );
                                }}
                              />
                            ))}

                            <Tooltip
                              content={(payload) => {
                                if (payload.active) {
                                  const tooltipData: {
                                    absSharePercent: number;
                                    impactPoints: number;
                                    isMajor: boolean;
                                    name: string;
                                  }[] = new Array(payload.payload?.length)
                                    .fill(null)
                                    .map((_, i) => payload.payload![0].payload[`product${i}`]) as any;
                                  return (
                                    <div className='flex self-start flex-col bg-white rounded-lg p-3 border shadow text-xs text-zinc-600'>
                                      <div className='flex justify-between items-center gap-x-6'>
                                        <div className='font-semibold text-zinc-600'>{payload.label}</div>
                                      </div>
                                      {tooltipData.map((item, i) => (
                                        <div key={i} className='grid grid-cols-[2fr_1fr_1fr] items-center gap-x-6'>
                                          <div>{item.name}</div>
                                          <div>impact</div>
                                          <div className='flex items-center gap-x-1'>
                                            <div className='text-zinc-900 text-sm'>{simplify(Number(item.impactPoints))}</div>
                                            <div className='uppercase text-[10px] text-zinc-600'>impact p.</div>
                                          </div>

                                          <div></div>
                                          <div>Contribution</div>
                                          <div className='flex items-center gap-x-1'>
                                            <div className='text-zinc-900 text-sm'>{roundToShort(Number(item.absSharePercent))}</div>
                                            <div className='uppercase text-zinc-600'>%</div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  );
                                }

                                return null;
                              }}
                              isAnimationActive={false}
                            />
                          </RadarChart>
                        );
                      })()}
                      <div></div>
                    </>
                  );
                })()}
              </div>
            )
          }
        />
        <Collapse
          title={(opened: boolean) => (
            <div className='w-full flex items-center justify-between'>
              <div className='flex gap-x-1'>
                <div>Impact by life cycle stage</div>
                {impactByLifecycleStageView === View.Table && <div className='text-sm self-end pb-0.5'>(sorted by total contribution)</div>}
              </div>
              {opened && (
                <div onClick={(e) => e.preventDefault()} className='flex gap-x-2 items-center'>
                  <select
                    value={selectedImpact.id}
                    onChange={({ target }) => {
                      if (target.value === ImpactId.Overall) {
                        setSelectedImpactValueType(ImpactValueType.Points);
                      }
                      setSelectedImpact(allImpactCategories.find((impact) => impact.id === target.value)!);
                    }}
                    className={cn('border rounded-full text-sm px-2 pr-3 py-1.5 focus:ring-0 hover:cursor-pointer w-56 truncate', {})}
                  >
                    {allImpactCategories.map((impact) => (
                      <option key={impact.id} value={impact.id}>
                        {impact.name}
                      </option>
                    ))}
                  </select>

                  <div
                    className={cn('border rounded-full px-2 py-1.5 flex h-full', {
                      'bg-zinc-50': selectedImpact.id === overallImpact.id,
                    })}
                  >
                    <button
                      type='button'
                      disabled={selectedImpact.id === overallImpact.id}
                      className='flex items-center gap-2'
                      onClick={() =>
                        setSelectedImpactValueType((current) =>
                          current === ImpactValueType.Points ? ImpactValueType.Physical : ImpactValueType.Points,
                        )
                      }
                    >
                      <div
                        className={cn(
                          'flex w-7 h-4 p-0.5 rounded-full',
                          selectedImpactValueType === 'physical' ? 'justify-end bg-brand' : 'bg-gray-300',
                        )}
                      >
                        <div className='bg-white rounded-full w-3 aspect-square' />
                      </div>
                      <div className={cn('text-sm', { 'text-zinc-400': selectedImpact.id === overallImpact.id })}>Physical impact</div>
                    </button>
                  </div>
                  <ViewToggle disabled={activateReference} view={impactByLifecycleStageView} setView={setImpactByLifecycleStageView} />
                  <button
                    disabled={waitingImpactByLifecycleStage}
                    onClick={() => {
                      setWaitingImpactByLifecycleStage(true);
                      getCompareCsvOutput<CsvType.impactByStage>(
                        `Sustained Product Comparison - Impact by life cycle stage ${format(new Date(), 'yyyy-MM-dd')}`,
                        comparePayload,
                        {
                          reportTarget: CsvType.impactByStage,
                          reportLifecycleLens: (selectedImpact.id === ImpactId.Overall ? 'totalImpact' : selectedImpact.id) as CsvImpactId,
                        },
                      ).call({
                        ok: () => {
                          setWaitingImpactByLifecycleStage(false);
                        },
                        fail: () => {
                          setWaitingImpactByLifecycleStage(false);
                        },
                      });
                    }}
                    className={cn(
                      { 'cursor-wait': waitingImpactByCategory },
                      'flex gap-2 items-center px-4 text-brandDarkPurple2 font-semibold text-base',
                    )}
                  >
                    <FontAwesomeIcon icon={solid('download')} />
                    Export as CSV
                  </button>
                </div>
              )}
            </div>
          )}
          body={
            impactByLifecycleStageView === View.Graph ? (
              <div className={cn('grid grid-cols-5 gap-x-6 -ml-12 py-6')}>
                <div
                  className={cn(
                    {
                      2: 'col-span-2',
                      3: 'col-span-3',
                      4: 'col-span-4',
                      5: 'col-span-5',
                    }[data.reports.length],
                  )}
                >
                  <MultiBarStackedChart
                    data={data.reports.map((report) => report.analysis)}
                    model={false}
                    type={selectedImpactValueType}
                    selectedImpact={selectedImpact}
                  />
                </div>
              </div>
            ) : (
              (() => {
                const stages = data.reports[0].analysis.lifecycleStageImpacts;

                const getColor = (name: string) => {
                  switch (name) {
                    case 'ingredient':
                      return '#14532D';
                    case 'packaging_raw_material':
                      return '#84CC16';
                    case 'production_process':
                      return '#C2410C';
                    case 'packaging_process':
                      return '#F97316';
                    case 'dist_transport':
                      return '#4F00FF';
                    case 'production_transport':
                      return '#4F00FF';
                    case 'dist_conservation':
                      return '#701A75';
                    case 'conservation_consumer':
                      return '#701A75';
                    case 'production_conservation':
                      return '#701A75';
                    case 'dist_loss':
                      return '#475569';
                    case 'preparation_step':
                      return '#0E7490';
                    case 'consumer_waste':
                      return '#475569';
                    case 'packaging_disposal':
                      return '#94A3B8';

                    default:
                      return '#ffffff';
                  }
                };

                return (
                  <div className='mt-6'>
                    <div className='grid grid-cols-5 gap-x-6'>
                      {data.reports.map((report, index) => (
                        <Fragment key={index}>
                          <FullContributionTableModal
                            name={report.product.name}
                            skuId={report.product.skuId}
                            productType={report.product.productType}
                            scaledProductAmount={report.scope.scaledProductAmount}
                            selectedUnit={selectedReportUnit}
                            rowsCount={report.analysis.lifecycleStageImpacts.length}
                            id={report.product.id}
                            workspaceSid={report.product.workspaceSid}
                            parentId={report.product.parentId}
                          />
                        </Fragment>
                      ))}
                    </div>
                    <div className='grid grid-flow-row gap-y-6 py-6'>
                      {stages.map((stage, index) => (
                        <div key={index} className='grid grid-cols-5 gap-x-6'>
                          {data.reports.map((report, index1) => {
                            const impactUnit = report.analysis.impactStagesMatrix.find(
                              (impact) => impact.impactId === selectedImpact.id,
                            )?.unit;

                            const getRelativeImpact = (report: ComparedProductReport) =>
                              selectedImpact.id === ImpactId.Overall
                                ? report.analysis.lifecycleStageImpacts.find((s) => s.id === stage.id)
                                : report.analysis.impactStagesMatrix
                                    .find((impact) => impact.impactId === selectedImpact.id)!
                                    .stages.map((s) => ({
                                      ...s,
                                      absImpactSharePercent: s.absSharePercent,
                                    }))
                                    .find((s) => s.id === stage.id)!;

                            const stageImpact = getRelativeImpact(report);

                            if (stageImpact) {
                              return (
                                <div key={index1}>
                                  <div className='grid grid-flow-row self-start gap-y-1 pl-4'>
                                    <div
                                      onClick={() => handleExpandedState(index1 + stageImpact.id)}
                                      className='cursor-pointer flex items-center gap-x-2 font-semibold -ml-8'
                                    >
                                      <div className='flex items-center justify-center size-6'>
                                        <FontAwesomeIcon
                                          className={cn('size-5 text-zinc-400', { '-rotate-90': !expanded.includes(index1 + stage.id) })}
                                          icon={solid('caret-down')}
                                        />
                                      </div>
                                      <div>{stageImpact.name}</div>
                                    </div>
                                    {selectedImpactValueType === ImpactValueType.Points ? (
                                      <div className='flex items-center gap-x-1'>
                                        <div className='text-2xl'>{simplify(stageImpact.impactPoints)}</div>
                                        <div className='text-sm'>impact points</div>
                                      </div>
                                    ) : (
                                      <div className='flex items-center gap-x-1'>
                                        <div className='text-2xl'>{simplify((stageImpact as { physicalValue: number }).physicalValue)}</div>
                                        <div className='text-sm'>{impactUnit}</div>
                                      </div>
                                    )}
                                    <div className='flex items-center gap-x-1'>
                                      <div className='text-xl'>{roundToShort(stageImpact.absImpactSharePercent)}%</div>
                                      <div className='text-sm'>of total contribution</div>
                                    </div>
                                    {canShowCompareResult && (
                                      <div className='flex self-start pt-2'>
                                        {comparedWithReferenceResult(
                                          {
                                            id: report.product.id,
                                            value:
                                              selectedImpactValueType === ImpactValueType.Points
                                                ? stageImpact.impactPoints
                                                : (stageImpact as { physicalValue: number }).physicalValue,
                                          },
                                          {
                                            id: getReferenceProduct(data.reports, selectedReference)!.product.id!,
                                            value: getRelativeImpact(getReferenceProduct(data.reports, selectedReference)!)!.impactPoints!,
                                          },
                                        )}
                                      </div>
                                    )}
                                  </div>

                                  <div className='flex flex-col' key={index}>
                                    {(() => {
                                      const data =
                                        selectedImpact.id === ImpactId.Overall
                                          ? report.analysis.lifecycleStageImpacts.find((stage2) => stage.id === stage2.id)?.components!
                                          : report.analysis.impactStagesMatrix
                                              .find((impact) => impact.impactId === selectedImpact.id)!
                                              .stages.find((stage2) => stage2.id === stage.id)?.components;

                                      return data?.map((component, index) => {
                                        return (
                                          <div key={index}>
                                            {expanded.includes(index1 + stage.id) && (
                                              <div className='py-4'>
                                                <div
                                                  style={
                                                    {
                                                      backgroundColor: getColor(component.id),
                                                    } as CSSProperties
                                                  }
                                                  className='flex flex-col border rounded-lg overflow-hidden'
                                                >
                                                  <div className='flex flex-col rounded-l-lg ml-0.5 py-3 px-4 bg-white'>
                                                    <div className='font-semibold'>{component.name}</div>
                                                    <div>{simplify(component.impactPoints)} impact points</div>
                                                    <div>
                                                      {selectedImpact.id === ImpactId.Overall
                                                        ? roundToShort((component as Component).absImpactSharePercent)
                                                        : roundToShort((component as MatrixComponent).absSharePercent)}
                                                      %
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        );
                                      });
                                    })()}
                                  </div>
                                </div>
                              );
                            }

                            return undefined;
                          })}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })()
            )
          }
        />
        <Collapse
          title={(opened) => (
            <div className='flex w-full items-center justify-between'>
              <div className='flex gap-x-1'>
                <div>Most relevant processes</div>
                <div className='text-sm self-end pb-0.5'>(sorted by total contribution)</div>
              </div>

              {opened && (
                <button
                  disabled={waitingRelevantProcesses}
                  onClick={() => {
                    setWaitingRelevantProcesses(true);
                    getCompareCsvOutput<CsvType.relevantProcesses>(
                      `Sustained Product Comparison - Most relevant processes ${format(new Date(), 'yyyy-MM-dd')}`,
                      comparePayload,
                      {
                        reportTarget: CsvType.relevantProcesses,
                      },
                    ).call({
                      ok: () => {
                        setWaitingRelevantProcesses(false);
                      },
                      fail: () => {
                        setWaitingRelevantProcesses(false);
                      },
                    });
                  }}
                  className={cn(
                    { 'cursor-wait': waitingImpactByCategory },
                    'flex gap-2 items-center px-4 text-brandDarkPurple2 font-semibold text-base',
                  )}
                >
                  <FontAwesomeIcon icon={solid('download')} />
                  Export as CSV
                </button>
              )}
            </div>
          )}
          body={
            <div className='grid grid-cols-5 gap-x-6 gap-y-20'>
              {data.reports.map((report, index) => (
                <div className='flex flex-col py-6' key={index}>
                  {report.analysis.majorProcesses.map((process, i) => (
                    <div className='h-44 flex flex-col gap-y-1' key={i}>
                      {(() => {
                        const arrayFromString = process.name.split(' - ');

                        return (
                          <div className='flex flex-col gap-y-1'>
                            <div className='font-semibold'>{arrayFromString.splice(0, 1)}</div>
                            <div title={arrayFromString.join(' > ')} className='line-clamp-3'>
                              {'> '}
                              {arrayFromString.join(' > ')}
                            </div>
                          </div>
                        );
                      })()}
                      <div className='flex items-center gap-x-2'>
                        <div className='text-xl'>{roundToShort(process.sharePercent)}%</div>
                        <div>of total contribution</div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          }
        />
      </div>
    </div>
  );
};

const ViewToggle = ({ view, setView, disabled }: { view: View; setView: (v: SetStateAction<View>) => void; disabled: boolean }) => {
  return (
    <select
      value={view}
      disabled={disabled}
      onChange={({ target }) => setView(target.value as View)}
      className={cn(
        'disabled:border-zinc-200 disabled:bg-zinc-50 disabled:text-zinc-400 disabled:cursor-not-allowed border rounded-full text-sm px-2 pr-3 py-1.5 focus:ring-0 hover:cursor-pointer',
        {},
      )}
    >
      <option value={View.Graph}>{View.Graph}</option>
      <option value={View.Table}>{View.Table}</option>
    </select>
  );
};

interface FullContributionTableModalProps {
  name: string;
  skuId: string;
  productType: ProductType;
  rowsCount: number;
  scaledProductAmount: Amount;
  selectedUnit: 'kg' | 'serving' | 'sku';
  id: string;
  workspaceSid: string;
  parentId?: string;
}

const FullContributionTableModal = (props: FullContributionTableModalProps) => {
  const [data, setData] = useState<{
    lifecycleStageImpacts: LifecycleStageImpact[];
    overallImpact: number;
    impactPoints: number;
  }>();
  const ref = useRef<ModalFormApi>(null);
  const rowsCount = props.rowsCount;

  const basicDetails = (
    <div className='flex flex-col gap-y-6'>
      <div className='text-lg font-semibold leading-snug'>{props.name}</div>
      <div className='flex flex-col text-zinc-500 text-sm leading-tight'>
        <div>Product ID: {props.skuId}</div>
        <div>
          Product type:{' '}
          {
            {
              [ProductType.Final]: 'Final',
              [ProductType.Intermediate]: 'Intermediate',
              [ProductType.Internal]: 'Internal',
            }[props.productType]
          }
        </div>
      </div>
    </div>
  );
  return (
    <Fragment>
      <ModalV3
        hideConfirm
        ref={ref}
        size='wide'
        title={<div>Final environmental impact and contribution per life cycle stage and underlying process</div>}
        onClose={() => setData(undefined)}
        body={
          <div className='flex flex-col gap-y-6 -mt-6'>
            {!data ? (
              <>
                {basicDetails}
                <div className='grid grid-flow-row gap-y-2'>
                  {Array(rowsCount)
                    .fill(null)
                    .map((_, i) => (
                      <div key={i} className='loading-skeleton w-full h-[54px]'></div>
                    ))}
                  <div className='loading-skeleton h-[46px]'></div>
                </div>
              </>
            ) : (
              <>
                {basicDetails}
                <ByStageContributionTable
                  selectedImpact={overallImpact}
                  data={data.lifecycleStageImpacts.map((impact) => ({
                    ...impact,
                    value: impact.impactValue,
                    absSharePercent: impact.absImpactSharePercent,
                    components: fromStageImpactsComponent(impact.components),
                  }))}
                  totalImpact={data.overallImpact}
                  totalPoints={data.impactPoints}
                />
              </>
            )}
          </div>
        }
      ></ModalV3>
      <button
        onClick={() => {
          if (props.parentId) {
            getModellingReportV3(props.parentId, props.id, props.selectedUnit, props.workspaceSid).call({
              ok: (data) =>
                setData({
                  lifecycleStageImpacts: data.calculations.model.analysis.lifecycleStageImpacts,
                  overallImpact: data.calculations.model.overallImpact,
                  impactPoints: data.calculations.model.impactPoints,
                }),
            });
          } else {
            getProductReport(props.id, {
              search: {
                lens: Lens.Production,
                scaleToAmount: props.scaledProductAmount.value,
                scaleUnit: props.scaledProductAmount.unit.name,
              },
              workspaceSid: props.workspaceSid,
            }).call({
              ok: (data) =>
                setData({
                  lifecycleStageImpacts: data.analysis.lifecycleStageImpacts,
                  overallImpact: data.overallImpact,
                  impactPoints: data.impactPoints,
                }),
            });
          }

          ref.current!.open();
        }}
        className='flex justify-between items-center self-center text-violet-950 text-sm font-semibold rounded-lg shadow bg-slate-200 py-1.5 px-4'
      >
        <div>Full contribution table</div>
        <FontAwesomeIcon icon={regular('arrow-up-right')} />
      </button>
    </Fragment>
  );
};
