import { MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { Step1 } from './1_Step';
import { Step2 } from './2_Step';
import { TaggableFieldsContainer } from '../TaggableFields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Form, Formik, useFormikContext } from 'formik';
import { Methodology, ProductStage, ProductType, ProductV3, validateProduct } from '../../../../../api';
import { Props as TitleBadgesProps, TitleBadges } from '../TitleBadges';
import { getIngredientsFromAll, getPackagingsFromAll, getStepsFromAll } from '../dataModel';
import round from 'lodash/round';
import { ModalApi, ModalV3 } from '../../../../../components/ModalV3';
import { useProfile } from '../../../../../hooks/useProfile';
import { useLocation } from 'react-router';
import * as yup from 'yup';
import { useLists } from '../../../../../hooks/useLists';
import cloneDeep from 'lodash/cloneDeep';
import cn from 'classnames';
import { ValidationErrors } from '../ValidationErrors';

interface Props extends TitleBadgesProps {
  title: string;
  ignoreValuesChange: MutableRefObject<boolean>;
}

export const BasicInfo = (props: Props) => {
  const productFormik = useFormikContext<ProductV3>();
  const profile = useProfile();
  const [triedSubmitting, setTriedSubmitting] = useState(false);
  const location = useLocation();
  const rescaleModal = useRef<ModalApi>(null);
  const [newValues, setNewValues] = useState(productFormik.values);
  const [originalValues] = useState(productFormik.values);
  const [duplicated] = useState(new URLSearchParams(location.search).has('duplicated'));
  const productStage = new URLSearchParams(location.search).get('stage') as ProductStage | null;
  const [skuIdInUse, setSkuIdInUse] = useState(false);
  const stepFromGraph = useLocation().state?.step;
  const [step, setStep] = useState(stepFromGraph ?? 1);

  return (
    <>
      <ModalV3
        ref={rescaleModal}
        size='narrow'
        title='Automatic rescaling'
        cancelLabel='Skip'
        confirmLabel='Rescale'
        onCancel={() => {
          productFormik.setValues(newValues);
        }}
        onConfirm={() => {
          const values = cloneDeep(newValues);
          const scale = newValues.amount.value / originalValues.amount.value;

          getIngredientsFromAll(values.nodes).forEach((ingredient) => {
            ingredient.amount.value = round(ingredient.amount.value * scale, 1);
          });
          getPackagingsFromAll(values.nodes).forEach((packaging) => {
            packaging.amount.value = round(packaging.amount.value * scale, 1);
          });
          getStepsFromAll(values.nodes).forEach((step) => {
            step.inputs.forEach((input) => {
              input.amountValue = round(input.amountValue * scale, 1);
            });
            step.outputs.forEach((output) => {
              output.amount!.value = round(output.amount!.value * scale, 1);
            });
          });

          productFormik.setValues(values);
        }}
        body={
          <>
            The net amount you just entered ({newValues.amount?.value}
            {newValues.amount?.unit.name}) is different from that of the original product you duplicated ({originalValues.amount?.value}
            {originalValues.amount?.unit.name}). Do you want us to automatically rescale all of the product’s content to match the new net
            amount?
          </>
        }
      />
      <Formik
        initialValues={{
          ...productFormik.values,
          stage: productStage ?? productFormik.values.stage,
          conservation: productFormik.values.conservation ?? undefined,
          amount: productFormik.values.amount ?? undefined,
          economicValue: productFormik.values.economicValue ?? undefined,
        }}
        validateOnBlur={triedSubmitting}
        validateOnChange={triedSubmitting}
        validationSchema={yup.object().shape({
          name: yup.string().required(),
          skuId: yup.string().required(),
          category: yup.object().when('productType', {
            is: ProductType.Internal,
            then: (schema) => schema.nullable(),
            otherwise: (schema) => schema.required(),
          }),
          foodType: yup.object().when('productType', {
            is: ProductType.Internal,
            then: (schema) => schema.nullable(),
            otherwise: (schema) => schema.required(),
          }),
          conservation: yup.object().shape({
            requirement: yup.object().required(),
          }),
          amount: yup.object().shape({
            value: yup.number().positive().required(),
            unit: yup.object().required(),
          }),
          economicValue: yup
            .object()
            .nullable()
            .shape({
              price: yup
                .number()
                .nullable()
                .test('price-and-currency', 'Price is required if currency is specified', function (value) {
                  const { currency } = this.parent;
                  if (currency) {
                    return !!value;
                  }
                  return true;
                }),
              currency: yup
                .object()
                .nullable()
                .test('currency-and-price', 'Currency is required if price is specified', function (value) {
                  const { price } = this.parent;
                  if (price) {
                    return !!value;
                  }
                  return true;
                }),
            }),
          servings: yup.number().integer().positive().nullable(),
          ...(profile.selectedWorkspace.methodology.type === Methodology.FoundationEarth
            ? {
                rawToCookedRatio: yup.number().positive().required(),
              }
            : {}),
        })}
        onSubmit={(values, form) => {
          if (duplicated && typeof originalValues.amount?.value === 'number' && originalValues.amount.value !== values.amount.value) {
            setNewValues(values);
            rescaleModal.current!.open();
          } else {
            validateProduct(values).ok(async ({ validation }) => {
              if (validation.errors.length > 0) {
                props.ignoreValuesChange.current = true;
                if (validation.errors.find((error) => error.text === 'This SKU ID already exists in your account')) {
                  setSkuIdInUse(true);
                }
                productFormik.setFieldValue('validation', {
                  warnings: productFormik.values.validation.warnings,
                  errors: validation.errors,
                });
              } else {
                setSkuIdInUse(false);
                props.ignoreValuesChange.current = false;
                productFormik.setValues(values);
              }
            });
          }

          form.setSubmitting(false);
        }}
      >
        <TaggableFieldsContainer>
          <Content
            {...props}
            step={step}
            setStep={setStep}
            triedSubmitting={() => setTriedSubmitting(true)}
            skuIdInUse={skuIdInUse}
            setSkuIdInUse={setSkuIdInUse}
          />
        </TaggableFieldsContainer>
      </Formik>
    </>
  );
};

const Content = (
  props: Props & {
    step: number;
    setStep: (v: SetStateAction<number>) => void;
    triedSubmitting: () => void;
    skuIdInUse: boolean;
    setSkuIdInUse: (v: SetStateAction<boolean>) => void;
  },
) => {
  const formik = useFormikContext<ProductV3>();
  const saving = props.waiting || formik.isValidating || formik.isSubmitting;
  const lists = useLists();

  useEffect(() => {
    if (formik.values.foodType && !formik.values.amount?.unit) {
      formik.setFieldValue('amount.unit', lists.foodTypes.find(({ type }) => type === formik.values.foodType.type)?.unit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.foodType]);

  useEffect(() => {
    props.setSkuIdInUse(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.skuId]);

  return (
    <Form>
      <div className='flex items-start justify-center -mt-4 -mx-6 bg-neutral-50 xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))] min-h-[calc(100vh_-_theme(spacing.20))]'>
        <div className='p-12 flex flex-col max-w-screen-xl w-full'>
          <div className='flex flex-col gap-y-10 justify-between h-full w-full bg-white border rounded-2xl p-6'>
            <div className='grid grid-cols-3 items-center'>
              <button
                type='button'
                onClick={() => props.setStep(1)}
                className={cn(
                  { invisible: props.step === 1 },
                  'size-8 flex items-center justify-center bg-slate-100 rounded-lg active:scale-95',
                )}
              >
                <FontAwesomeIcon className='size-5' icon={regular('chevron-left')} />
              </button>
              <div className='flex justify-self-center uppercase tracking-wide text-xs text-zinc-600 py-2 px-4 border rounded-full'>
                product details {props.step}/2
              </div>
              <div className='flex gap-x-4 items-center justify-self-end'>
                <TitleBadges {...props} />
                <ValidationErrors ignoreLessImportant />
                <button
                  type='button'
                  onClick={() => props.setStep(2)}
                  className={cn(
                    { invisible: props.step === 2 },
                    'size-8 flex items-center justify-center bg-slate-100 rounded-lg active:scale-95',
                  )}
                >
                  <FontAwesomeIcon className='size-5' icon={regular('chevron-right')} />
                </button>
              </div>
            </div>
            {props.step === 1 ? <Step1 /> : <Step2 {...props} />}
            <div className='flex items-center justify-between *:py-2 *:px-4 *:rounded-full *:font-semibold'>
              {props.step === 2 ? (
                <button type='button' onClick={() => props.setStep(1)} className='text-violet-950 bg-slate-200'>
                  Back
                </button>
              ) : (
                <div />
              )}
              {props.step === 1 ? (
                <button type='button' onClick={() => props.setStep(2)} className='bg-brand text-white'>
                  Next
                </button>
              ) : (
                <button
                  type='button'
                  onClick={async () => {
                    props.triedSubmitting();
                    await formik.submitForm();
                  }}
                  disabled={saving || !formik.isValid}
                  className={cn('bg-brand px-4 py-2 text-white', '[&:active:not(:disabled)]:scale-95 disabled:bg-neutral-300', {
                    'disabled:cursor-wait': saving,
                  })}
                >
                  Life cycle graph
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};
