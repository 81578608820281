import { Fragment, useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Label, ResponsiveContainer, Text, Tooltip, XAxis, YAxis } from 'recharts';
import { Analysis, ImpactId, ImpactStageMatrix, LifecycleStageImpact, LifeCycleStageName } from '../../../../../../api';
import { ImpactValueType } from '../../Sku/Overview';
import { simplify } from '../../../../shared';
import camelCase from 'lodash/camelCase';

interface Props {
  data: Analysis[];
  model: boolean;
  type: ImpactValueType;
  selectedImpact: {
    id: ImpactId;
    name: string;
    unit?: string;
  };
}

export const MultiBarStackedChart = (props: Props) => {
  const [data, setData] = useState<{}[]>();

  const getDataFromStages = (lifecycleStageImpacts: LifecycleStageImpact[]) =>
    lifecycleStageImpacts.reduce(
      (ls, v) => ({
        ...ls,
        [camelCase(v.name.replace(' ', ''))]: v.impactPoints,
      }),
      {},
    );

  const getDataFromMatrix = (impactStageMatrix: ImpactStageMatrix[]) =>
    impactStageMatrix
      .find((v) => v.impactId === props.selectedImpact.id)!
      .stages.reduce(
        (ls, v) => ({
          ...ls,
          [camelCase(v.name.replace(' ', ''))]: props.type === ImpactValueType.Points ? v.impactPoints : v.absPhysicalValue,
        }),
        {},
      );

  useEffect(() => {
    if (props.selectedImpact.id === ImpactId.Overall) {
      setData(props.data.map((v) => getDataFromStages(v.lifecycleStageImpacts)));
    } else {
      setData(props.data.map((v) => getDataFromMatrix(v.impactStagesMatrix)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedImpact, props.type]);

  return (
    <ResponsiveContainer height={320} width='100%'>
      <BarChart margin={{ right: 25 }} data={data}>
        <CartesianGrid vertical={false} />
        <XAxis
          padding={{ left: -60, right: -10 }}
          tickSize={0}
          axisLine={false}
          tick={(props) => (
            <Text
              fill={props.model ? (props.payload.index === 0 ? 'black' : '#4f00ff') : 'black'}
              fontSize={14}
              fontWeight='bold'
              x={props.x}
              y={props.y + 15}
              textAnchor={props.textAnchor}
            >
              {props.model ? (props.payload.index === 0 ? 'Original' : 'Modelled') : `Product ${props.payload.index + 1}`}
            </Text>
          )}
        />
        <YAxis
          width={100}
          label={
            <Label
              style={{ fontSize: '16px' }}
              value={props.type === ImpactValueType.Points ? 'Impact points' : props.selectedImpact.unit}
              position='middle'
              angle={-90}
            />
          }
          tickSize={0}
          axisLine={false}
          tick={(props) => (
            <Text fill='grey' style={{ fontSize: 10 }} x={props.x} y={props.y} textAnchor={props.textAnchor}>
              {String(simplify(props.payload.value))}
            </Text>
          )}
        />
        {[
          {
            name: 'Raw Materials',
            bgColor: '#5BB7D0',
          },
          {
            name: 'Production',
            bgColor: '#31A383',
          },
          {
            name: 'Distribution',
            bgColor: '#96BF73',
          },
          {
            name: 'Use',
            bgColor: '#8F7DB3',
          },
          {
            name: 'End Of Life',
            bgColor: '#9BB8DF',
          },
        ].map(({ name, bgColor }, i2) => (
          <Bar
            key={i2}
            stackId='1'
            left={30}
            barSize={80}
            fill={bgColor}
            minPointSize={5}
            isAnimationActive={false}
            dataKey={camelCase(name.replace(' ', ''))}
          />
        ))}
        <Tooltip
          wrapperStyle={{ zIndex: 1 }}
          cursor={{ fill: 'none' }}
          content={({ payload: payload1 }) => (
            <div className='bg-white rounded-2xl px-6 py-4 border shadow-md grid grid-cols-[max-content_max-content] gap-x-4 gap-y-2'>
              {payload1 &&
                payload1.map(({ name, color, payload }, i) => (
                  <Fragment key={i}>
                    <div style={{ color }}>
                      {
                        {
                          rawMaterials: LifeCycleStageName.RawMaterials,
                          production: LifeCycleStageName.Production,
                          distribution: LifeCycleStageName.Distribution,
                          use: LifeCycleStageName.Use,
                          endOfLife: LifeCycleStageName.EndOfLife,
                        }[name as string]
                      }
                    </div>
                    <div>
                      <span>{simplify(payload[name as string])} </span>
                      <span>{props.type === ImpactValueType.Points ? 'Impact points' : props.selectedImpact.unit}</span>
                    </div>
                  </Fragment>
                ))}
            </div>
          )}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
