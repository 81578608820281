import { Route, Routes, useLocation } from 'react-router';
import { Compare } from './Compare';
import { Details } from './Details';
import { Import } from './Import';
import { List } from './List';
import { Modelling } from './Modelling';
import { Main as ModellingReport } from './Report/Modelling';
import { RevisionOverview } from './Report/RevisionOverview';
import { Main as ProductReport } from './Report/Sku';

export const Products = () => {
  const pathname = useLocation().pathname;

  const keyword = 'compare';
  const endIndex = pathname.indexOf(keyword) + keyword.length;

  const entitiesCount =
    pathname
      .slice(endIndex)
      .split('/')
      .filter((item) => item !== '').length / 4;

  const buildPath = () => {
    let path = '';
    for (let i = 0; i < entitiesCount; i++) {
      path += `/:pw${i + 1}Id/:p${i + 1}Id/:mw${i + 1}Id/:m${i + 1}Id`;
    }

    return path;
  };

  return (
    <Routes>
      <Route path={`compare${buildPath()}`} element={<Compare />} />
      <Route path=':productId/revisions/:revisionId/overview' element={<RevisionOverview />} />
      <Route path=':productId/report/*' element={<ProductReport />} />
      <Route path=':productId/models/:modelId/report/*' element={<ModellingReport />} />
      <Route path=':productId/models/:modelId/*' element={<Modelling />} />
      <Route path=':productId/revisions/:revision/*' element={<Details />} />
      <Route path=':productId/*' element={<Details />} />
      {/*<Route path='compare/:pw1Id/:product1Id/:mw1Id/:model1Id/:pw2Id/:product2Id/:mw2Id/:model2Id' element={<Compare />} />*/}
      <Route path='import/*' element={<Import />} />
      <Route path='*' element={<List />} />
    </Routes>
  );
};
