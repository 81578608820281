import { ReactNode, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

export const Collapse = ({ title, body }: { title: (opened: boolean) => ReactNode; body: ReactNode }) => {
  const [opened, setOpened] = useState(false);

  return (
    <details open className='-mx-6 px-6 xl:-mx-3 xl:px-3' onToggle={(e) => setOpened((e.target as any).open)}>
      <summary className='cursor-pointer py-4 marker:content-[""] border-b border-zinc-200'>
        <div className='flex items-center gap-x-4 cursor-pointer text-xl text-zinc-900'>
          <FontAwesomeIcon className={cn('text-xl flex self-center', { 'rotate-90': opened })} icon={light('chevron-right')} />
          {title(opened)}
        </div>
      </summary>
      {body}
    </details>
  );
};
