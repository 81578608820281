import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  BaselinePefReport,
  ForecastReport,
  getManufacturingBaselineReportCsv,
  getManufacturingForecastReportCsv,
  getManufacturingHistoricalReportCsv,
  HistoricalPefReport,
  ImpactId,
  ReportType,
} from '../../../../api';
import { ByImpactContributionTable } from '../../../../components/ByImpactContributionTable';
import { ByStageContributionTable, fromMatrixComponent, fromStageImpactsComponent } from '../../../../components/ByStageContributionTable';
import { SingleSelect } from '../../../../components/SingleSelect';
import { ViewToggle } from '../../../../components/ViewToggle';
import { LifeCycleStagesLegend } from '../../../../components/charts/LifeCycleStagesLegend';
import { PieChart } from '../../../../components/charts/PieChart';
import { RadarChart } from '../../../../components/charts/RadarChart';
import { convertFromLifecycleStageImpacts, SunburstChart } from '../../../../components/charts/SunburstChart/SunburstChart';
import { lifeCycleStagesColorHexSchema, setColor } from '../../../../components/charts/shared';
import { useCsvByLifeCycleStageHref } from '../../../../hooks/useCsvByLifeCycleStageHref';
import { ImpactValueType } from '../../Products/Report/Sku/Overview';

interface Props {
  focused: boolean;
  setFocused: (value: boolean) => void;
  data: ForecastReport | BaselinePefReport | HistoricalPefReport;
  type: ReportType;
}

export enum View {
  Graph = 'Graph view',
  Table = 'Table view',
}

export const ImpactAnalysisResultSummary = (props: Props) => {
  const [selectedView, setSelectedView] = useState<View>(View.Graph);
  const overallImpact = { id: ImpactId.Overall, name: 'Total environmental impact', disabled: false, unit: undefined };
  const [selectedImpact, setSelectedImpact] =
    useState<{ id: ImpactId; name: string; disabled: boolean; unit: string | undefined }>(overallImpact);
  const [selectedImpactUnit, setSelectedImpactUnit] = useState<ImpactValueType>(ImpactValueType.Points);
  const [exporting, setExporting] = useState(false);
  const csvByLifeCycleStageHref = useCsvByLifeCycleStageHref({
    data: props.data.analysis,
    selectedImpact: selectedImpact.id === ImpactId.Overall ? undefined : selectedImpact,
    hideLevelsAbove3: true,
  });
  const [expanded, setExpanded] = useState(false);
  const chartRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (props.focused) {
      setExpanded(true);
      setTimeout(() => {
        chartRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        tableRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 300);
    }
  }, [props.focused]);

  useEffect(() => {
    if (!expanded && props.setFocused) {
      props.setFocused(false);
    }
  }, [props, expanded]);

  return (
    <div className='flex flex-col gap-6 bg-lightBg print:bg-transparent border-t p-6'>
      <ViewToggle
        theme='dark'
        button1={{
          label: 'Graph view',
          icon: regular('chart-simple'),
          active: selectedView === View.Graph,
        }}
        button2={{
          label: 'Table view',
          icon: regular('table'),
          active: selectedView === View.Table,
        }}
        toggleView={() => setSelectedView((current) => (current === View.Graph ? View.Table : View.Graph))}
      />

      {selectedView === View.Graph ? (
        <>
          <div className='flex flex-col gap-6 print:grid-cols-1 w-2/3 print:w-full self-center'>
            <div className='flex flex-col border border-lightBgBorder2 bg-white rounded-2xl shadow-lg py-6 break-inside-avoid'>
              <div className='flex justify-center'>
                <div className='px-3 py-1 rounded-md text-brandDark font-semibold bg-brand/10'>By Impact Category</div>
              </div>
              <div className='h-full flex my-12 justify-center'>
                <RadarChart
                  meta={[{ dataKey: 'impactPoints', color: '#4f00ff' }]}
                  payload={props.data.analysis.impactStageMatrix.map((impact) => ({
                    ...impact,
                    keyName: 'Impact',
                    isMajor: impact.isMajorImpact,
                  }))}
                  tooltipType='single'
                />
              </div>
            </div>

            <div className='flex flex-col bg-white border border-lightBgBorder2 rounded-2xl shadow-lg py-6 break-inside-avoid'>
              <div className='flex justify-center'>
                <div className='px-3 py-1 rounded-md text-brandDark font-semibold bg-brand/10'>By Life Cycle Stage</div>
              </div>
              <div className='flex justify-center h-full'>
                <PieChart
                  showTicks
                  size={380}
                  outerRadius={130}
                  selectedType='impactPoints'
                  data={props.data.analysis.lifecycleStageImpacts
                    .map((impact) => ({
                      ...impact,
                      bgColor: setColor({ key: impact.name }, lifeCycleStagesColorHexSchema),
                    }))
                    .map((impact) => ({
                      ...impact,
                      name: impact.name,
                      isMajor: impact.isMajorImpact,
                      value: impact.absImpactSharePercent,
                    }))}
                />
              </div>
              <div className='px-6 text-sm'>
                <LifeCycleStagesLegend renderMajor />
              </div>
            </div>

            <div className='flex flex-col bg-white border border-lightBgBorder2 rounded-2xl shadow-lg py-6'>
              <div className='flex justify-center'>
                <div className='px-3 py-1 rounded-md text-brandDark font-semibold mb-3 bg-brand/10'>By Process</div>
              </div>
              <div ref={chartRef} className='mt-6'>
                <SunburstChart
                  size={350}
                  data={convertFromLifecycleStageImpacts(
                    props.data.analysis.lifecycleStageImpacts.map((entity) => ({
                      ...entity,
                      bgColor: setColor({ key: entity.name }, lifeCycleStagesColorHexSchema),
                    })),
                  )}
                  renderRelevantProduct
                  products={props.data.products}
                />
              </div>
              <div className='text-sm px-6 pt-6'>
                <LifeCycleStagesLegend renderMajor />
              </div>
            </div>
          </div>
          {props.data.excludedProductCount !== undefined && props.data.excludedProductCount > 0 && (
            <div className='mt-3 flex gap-3 items-center px-6'>
              <FontAwesomeIcon className='text-red-300 text-3xl' icon={solid('circle-exclamation')} />
              <div className='text-red-500 font-semibold'>
                {props.data.excludedProductCount} products or models initially included in this forecast were since deleted from your
                workspace. As a result, they are not included in the analysis and results presented here.
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div ref={tableRef} className='rounded-regular shadow-regular p-6 bg-white'>
            <div className='flex mb-6'>
              <div className='flex-1' />
              <div className='w-fit m-auto py-1 px-4 rounded-md bg-brand/10 font-semibold text-gray-600 shadow-md'>By Impact Category</div>
              <div className='flex-1 flex justify-end'>
                <button
                  type='button'
                  disabled={exporting}
                  className='print:hidden flex gap-2 items-center px-4 text-brandDarkPurple2 font-semibold disabled:cursor-wait'
                  onClick={() => {
                    setExporting(true);
                    if (props.type === ReportType.Baseline) {
                      const { from, to } = {
                        from: (props.data as BaselinePefReport).startDate,
                        to: (props.data as BaselinePefReport).endDate,
                      };
                      const fileName = `Baseline impact by category (${format(new Date(from), 'ddMMyy')} - ${format(
                        new Date(to),
                        'ddMMyy',
                      )})`;
                      getManufacturingBaselineReportCsv(fileName).call({
                        ok: () => setExporting(false),
                        fail: () => setExporting(false),
                      });
                    }

                    if (props.type === ReportType.Forecast) {
                      const title = `Forecasted ${(props.data as ForecastReport).cycle} impact by category ${format(
                        new Date((props.data as ForecastReport).createdAt),
                        'ddMMyy',
                      )}`;
                      getManufacturingForecastReportCsv(props.data.id, title).call({
                        ok: () => setExporting(false),
                        fail: () => setExporting(false),
                      });
                    }

                    if (props.type === ReportType.Historical) {
                      const { from, to } = {
                        from: (props.data as HistoricalPefReport).startDate,
                        to: (props.data as HistoricalPefReport).endDate,
                      };
                      const title = `${format(new Date(from), 'ddMMyy')} - ${format(new Date(to), 'ddMMyy')} impact by category`;
                      getManufacturingHistoricalReportCsv(props.data.id, title).call({
                        ok: () => setExporting(false),
                        fail: () => setExporting(false),
                      });
                    }
                  }}
                >
                  <FontAwesomeIcon icon={solid('download')} />
                  Export as CSV
                </button>
              </div>
            </div>
            <ByImpactContributionTable
              data={props.data.analysis.impactStageMatrix.map((impact) => ({
                ...impact,
                id: impact.impactId,
                name: impact.impactName,
                contributionPercent: impact.absSharePercent,
              }))}
              totalImpact={props.data.overallImpact}
              totalPoints={props.data.impactPoints}
            />
          </div>
          <div className='flex flex-col gap-8 bg-white p-6 rounded-2xl shadow-regular items-center'>
            <div className='flex self-stretch'>
              <div className='flex-1' />
              <div className='flex self-center px-4 py-1 rounded-md bg-brand/10 font-semibold text-gray-600 print:shadow-none shadow-md'>
                By Life Cycle Stage & Process
              </div>
              <div className='print:hidden flex-1 flex justify-end'>
                <a
                  className='flex gap-2 items-center px-4 text-brandDarkPurple2 font-semibold text-base'
                  download={`${
                    (props.data as { name?: string }).name ?? 'Baseline Annual Volume Footprint Overview'
                  } - Impact by life cycle stage - ${selectedImpact.name}.csv`}
                  href={csvByLifeCycleStageHref}
                >
                  <FontAwesomeIcon icon={solid('download')} />
                  Export as CSV
                </a>
              </div>
            </div>
            <div className='print:hidden w-full'>
              <div className='flex flex-col gap-6 mb-6'>
                <div className='w-72 self-center'>
                  {(() => {
                    const impacts = [
                      overallImpact,
                      ...props.data.analysis.impactStageMatrix.map((impact) => ({
                        ...impact,
                        id: impact.impactId,
                        name: impact.impactName,
                        disabled: false,
                      })),
                    ];
                    return (
                      <SingleSelect
                        selectedId={selectedImpact.id}
                        options={impacts}
                        value={{
                          value: selectedImpact.id,
                          label: selectedImpact.name,
                        }}
                        setSelectedId={(v) => {
                          if (v === ImpactId.Overall) {
                            setSelectedImpactUnit(ImpactValueType.Points);
                          }
                          setSelectedImpact(impacts.find((impact) => impact.id === v)!);
                        }}
                      />
                    );
                  })()}
                </div>

                <ViewToggle
                  theme='dark'
                  button1={{
                    label: 'Final environmental impact',
                    active: selectedImpactUnit === 'impactPoints',
                    disabled: selectedImpact.id === ImpactId.Overall,
                  }}
                  button2={{
                    label: 'Physical Impact',
                    active: selectedImpactUnit === 'physical',
                    disabled: selectedImpact.id === ImpactId.Overall,
                  }}
                  toggleView={() =>
                    setSelectedImpactUnit((current) =>
                      current === ImpactValueType.Points ? ImpactValueType.Physical : ImpactValueType.Points,
                    )
                  }
                />
              </div>

              <ByStageContributionTable
                unit={selectedImpact.unit}
                showPhysicalValues={selectedImpactUnit === 'physical'}
                data={
                  selectedImpact.id === ImpactId.Overall
                    ? props.data.analysis.lifecycleStageImpacts.map((stage) => ({
                        ...stage,
                        value: stage.impactValue,
                        absSharePercent: stage.absImpactSharePercent,
                        components: fromStageImpactsComponent(stage.components),
                      }))
                    : props.data.analysis.impactStageMatrix
                        .find(({ impactId }) => impactId === selectedImpact.id)!
                        .stages.map((stage) => ({
                          ...stage,
                          absSharePercent: stage.absSharePercent,
                          value: selectedImpactUnit === 'physical' ? stage.physicalValue : stage.weightedNormalisedValue,
                          isMajorImpact: stage.isMajorStage,
                          components: fromMatrixComponent(stage.components, selectedImpactUnit === 'physical'),
                        }))
                }
                totalImpact={
                  selectedImpact.id === ImpactId.Overall
                    ? props.data.overallImpact
                    : selectedImpactUnit === 'physical'
                    ? props.data.analysis.impactStageMatrix
                        .find(({ impactId }) => impactId === selectedImpact.id)!
                        .stages.map(({ physicalValue }) => physicalValue)
                        .reduce((a, b) => a + b, 0)
                    : props.data.analysis.impactStageMatrix
                        .find(({ impactId }) => impactId === selectedImpact.id)!
                        .stages.map(({ weightedNormalisedValue }) => weightedNormalisedValue)
                        .reduce((a, b) => a + b, 0)
                }
                totalPoints={
                  selectedImpact.id === ImpactId.Overall
                    ? props.data.impactPoints
                    : props.data.analysis.impactStageMatrix
                        .find(({ impactId }) => impactId === selectedImpact.id)!
                        .stages.map(({ impactPoints }) => impactPoints)
                        .reduce((a, b) => a + b, 0)
                }
              />
            </div>

            <div className='hidden print:block'>
              <ByStageContributionTable
                data={props.data.analysis.lifecycleStageImpacts.map((impact) => ({
                  ...impact,
                  value: impact.impactValue,
                  absSharePercent: impact.absImpactSharePercent,
                  components: fromStageImpactsComponent(impact.components),
                }))}
                totalImpact={props.data.overallImpact}
                totalPoints={props.data.impactPoints}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
