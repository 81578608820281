import { Fragment, useState } from 'react';
import { ImpactId, ProductReport, ProductType } from '../../../../../../api';
import { ByStageContributionTable, fromMatrixComponent } from '../../../../../../components/ByStageContributionTable';
import { ContextualExamplesAssumptions } from '../../../../../../components/ContextualExamplesAssumptions';
import { HeatMapTableByImpact } from '../../../../../../components/HeatMapTableByImpact';
import { ImpactSelect } from '../../../../../../components/ImpactSelect';
import { LifeCycleStagesLegend } from '../../../../../../components/charts/LifeCycleStagesLegend';
import {
  convertFromImpactStagesMatrix,
  convertFromLifecycleStageImpacts,
  SunburstChart,
} from '../../../../../../components/charts/SunburstChart/SunburstChart';
import { CollapsibleSection } from '../../CollapsibleSection';
import { ImpactValueType } from '../Overview';
import { NavigationButtons } from '../components/NavigationButtons';
import { NormalisationFactor } from '../components/NormalisationFactor';

interface Props {
  data: ProductReport;
}

export const Appendix = (props: Props) => {
  const overallImpact = { id: ImpactId.Overall, name: 'Total environmental impact' };
  const [selectedImpact, setSelectedImpact] = useState<{ id: ImpactId; name: string }>(overallImpact);
  const [selectedImpactValueType, setSelectedImpactValueType] = useState<ImpactValueType>(ImpactValueType.Points);

  return (
    <div className='flex flex-col gap-6 px-6'>
      <div className='flex justify-between gap-8'>
        <div className='flex flex-col gap-6'>
          <div>
            This section of the report contains a number of Appendices with additional information on your product’s impact, our methodology
            and more. For ease of navigation and reference, these have been broken down into separate sections.
          </div>
          <div>
            If you are left with some questions pertaining to your product’s environmental impact after reading this report, please{' '}
            <a href='mailto:impact@sustained.com' className='underline hover:text-brandDark hover:cursor-pointer'>
              contact us
            </a>{' '}
            and we’ll be happy to help you in any way we can!
          </div>
        </div>
        <NavigationButtons type='icons' back={{ path: '../interpretation', label: 'Interpretation' }} />
      </div>
      {[
        {
          title: (index: number) => `Appendix ${index} - Detailed breakdown by impact category`,
          content: (
            <>
              <div className='text-base mt-6'>
                <div>
                  The interactive sunburst chart below shows the detailed breakdown by life cycle stage and underlying processes of each
                  impact category. Choose between the total impact or the physical impact and toggle between impact categories to see the
                  difference or click on various portions of the chart to better explore a branch of the tree.
                </div>
                <div className='print:hidden'>
                  <ImpactSelect
                    theme='dark'
                    selectedImpact={selectedImpact}
                    setSelectedImpact={setSelectedImpact}
                    selectedImpactValueType={selectedImpactValueType}
                    setSelectedImpactValueType={setSelectedImpactValueType}
                    impacts={props.data.analysis.impactStagesMatrix.map((category) => ({
                      id: category.impactId,
                      name: category.impactName,
                    }))}
                  />

                  <div className='h-[512px] flex flex-col items-center'>
                    <div className='mt-6 h-[400px] flex justify-center'>
                      {selectedImpact.id === ImpactId.Overall ? (
                        <SunburstChart size={350} data={convertFromLifecycleStageImpacts(props.data.analysis.lifecycleStageImpacts)} />
                      ) : (
                        <SunburstChart
                          size={350}
                          data={convertFromImpactStagesMatrix(
                            props.data.analysis.impactStagesMatrix.find(({ impactName }) => impactName === selectedImpact.name)!.stages,
                          )}
                          selectedCategory={props.data.analysis.impactStagesMatrix.find(({ impactId }) => selectedImpact.id === impactId)}
                          showPhysicalImpact={selectedImpactValueType === ImpactValueType.Physical}
                          selectedCategoryPhysicalUnit={
                            props.data.analysis.impactStagesMatrix.find(({ impactId }) => selectedImpact.id === impactId)!.unit
                          }
                        />
                      )}
                    </div>
                    <div className='w-1/2 mt-6'>
                      <LifeCycleStagesLegend renderMajor />
                    </div>
                  </div>
                </div>

                <div className='flex flex-col gap-3 mt-6'>
                  <div>
                    The table below shows the detailed breakdown by life cycle stage and underlying processes of each impact category. This
                    is done using physical values of each impact categories to understand the absolute impact.
                  </div>

                  <div>
                    Each row is colour coded independently from one another, showing for each impact category and process, the absolute
                    impacts at the life cycle stage level, colour coded according to its relative importance: a red to green colour scale,
                    from highest to lowest impact.
                  </div>

                  <div>
                    This heat map is included to provide visual cues as to which life cycle stages or processes are the most concerning for
                    each impact category, to identify general trends and later help you improve your product.
                  </div>
                </div>
                <div className='text-dark mt-10 rounded-xl print:rounded-none shadow-regular print:shadow-none print:-mx-8'>
                  <HeatMapTableByImpact data={props.data.analysis.impactStagesMatrix} />
                </div>
              </div>
            </>
          ),
        },
        ...(() => {
          return props.data.product.productType === ProductType.Internal
            ? []
            : [
                {
                  title: (index: number) => `Appendix ${index} - PEF recommended Relevance Analysis`,
                  content: (
                    <>
                      <div className='text-base mt-6 flex flex-col gap-4'>
                        <div>
                          By following PEF guidelines, results for ‘each more relevant impact category’, or major contributor as described
                          in this report, should be further explored through an in-depth Relevance Analysis.
                        </div>
                        <div>
                          As such, the&nbsp;
                          <span className='text-brandDark font-semibold'>
                            {props.data.analysis.impactStagesMatrix.filter((impact) => impact.isMajorImpact).length} impact categories
                          </span>
                          &nbsp;that together contribute a minimum of 80% of the total environmental impact, namely{' '}
                          {props.data.analysis.impactStagesMatrix
                            .filter((impact) => impact.isMajorImpact)
                            .map((impact) => (
                              <span key={impact.impactId}>
                                <span className='text-brandDark font-semibold'>{impact.impactName}</span>;&nbsp;
                              </span>
                            ))}
                          should be extracted from the rest of the analysis and carefully analysed.
                        </div>
                        <div>
                          Following PEF recommendations, a relevance analysis - a mechanism to understand the contribution each life cycle
                          stage and process has on the most relevant impact categories - is carried out focusing on these impact categories.
                        </div>
                        <div>
                          This analysis identifies those life cycle stages and underlying processes which contribute cumulatively more than
                          80% to a given impact category. They are the most relevant for your improvement work, where the largest portion of
                          your product’s impact lies and are highlighted in red throughout the section.
                        </div>

                        {props.data.analysis.impactStagesMatrix
                          .filter((impact) => impact.isMajorImpact)
                          .map(({ impactId, impactName, stages }) => (
                            <div className='flex flex-col' key={impactId}>
                              <div className='self-start px-3 py-1 mt-6 rounded-md text-brandDark font-semibold text-lg bg-brand/10'>
                                {impactName}
                              </div>
                              <div className='mt-3'>
                                The sunburst chart and table below show the detailed breakdown by life cycle stage and underlying processes
                                for this category. The chart visually represents the contributions of the parts to the total environmental
                                impact of <span className='font-semibold text-brandDark'>{impactName}</span>, with the major contributors or
                                ‘most relevant’ life cycle stages and processes highlighted in red.
                              </div>
                              <div className='mt-6 flex justify-center'>
                                <SunburstChart size={400} data={convertFromImpactStagesMatrix(stages)} />
                              </div>
                              <div className='flex justify-center mt-12'>
                                <LifeCycleStagesLegend renderMajor />
                              </div>

                              {(() => {
                                const impact = props.data.analysis.impactStagesMatrix.find((impact) => impact.impactId === impactId)!;
                                return (
                                  <div className='mt-6 rounded-2xl shadow-regular overflow-hidden'>
                                    <div className='text-lg font-semibold text-center p-4 text-dark'>
                                      Final environmental impact and contribution per life cycle stage and underlying process
                                    </div>
                                    <div className='p-3 border-t'>
                                      <ByStageContributionTable
                                        productName={props.data.product.name}
                                        selectedImpact={{
                                          id: impact.impactId,
                                          name: impact.impactName,
                                          unit: impact.unit,
                                        }}
                                        data={impact.stages.map((stage) => ({
                                          ...stage,
                                          value: stage.weightedNormalisedValue,
                                          isMajorImpact: stage.isMajorStage,
                                          impactSharePercent: stage.absSharePercent,
                                          components: fromMatrixComponent(stage.components),
                                        }))}
                                        totalImpact={impact.stages
                                          .map(({ weightedNormalisedValue }) => weightedNormalisedValue)
                                          .reduce((a, b) => a + b, 0)}
                                        totalPoints={impact.stages.map(({ impactPoints }) => impactPoints).reduce((a, b) => a + b, 0)}
                                      />
                                    </div>
                                  </div>
                                );
                              })()}
                            </div>
                          ))}
                      </div>
                    </>
                  ),
                },
              ];
        })(),
        {
          title: (index: number) => `Appendix ${index} - Normalisation and weighting factors`,
          content: <NormalisationFactor data={props.data.metadata!.methodFactors} />,
        },
        {
          title: (index: number) => `Appendix ${index} - Assumptions behind the contextual examples`,
          content: <ContextualExamplesAssumptions />,
        },
      ].map((item, i) => {
        return (
          <Fragment key={i}>
            <CollapsibleSection title={item.title(i + 1)}>{item.content}</CollapsibleSection>
          </Fragment>
        );
      })}
      <NavigationButtons type='buttons' back={{ path: '../interpretation', label: 'Interpretation' }} />
    </div>
  );
};
