import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart as RechartsRadarChart, Text, Tooltip, TooltipProps } from 'recharts';
import { simplify, roundToShort } from '../../Root/Main/shared';
import { alignTickPosition } from './shared';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

interface Props {
  meta: {
    dataKey: string;
    label?: string;
    color: string;
  }[];
  payload: { keyName?: string }[];
  height?: number;
  width?: number;
  innerRadius?: string;
  outerRadius?: number;
  selectedType?: 'physical' | 'impactPoints';
  tooltipType: 'single' | 'multiple';
}

export const RadarChart = (props: Props) => {
  const PolarChartCustomLabel = (props: {
    payload: { coordinate: number; value: string };
    x: number;
    y: number;
    textAnchor: 'start' | 'end' | 'middle';
  }) => {
    const shiftLabel = () => {
      if (props.payload.coordinate > -110 && props.payload.coordinate < -70) {
        return props.y + 10;
      } else if (props.payload.coordinate > 89 && props.payload.coordinate < 91) {
        return props.y + 12;
      } else {
        return props.y;
      }
    };

    return (
      <Text
        x={alignTickPosition({ x: props.x, y: props.y, textAnchor: props.textAnchor, coordinate: props.payload.coordinate })!.x}
        y={alignTickPosition({ x: props.x, y: shiftLabel(), textAnchor: props.textAnchor, coordinate: props.payload.coordinate })!.y}
        style={{ fontSize: '12px' }}
        textAnchor={props.textAnchor}
        width={120}
      >
        {props.payload.value}
      </Text>
    );
  };

  return (
    <RechartsRadarChart
      height={props.height ?? 300}
      width={props.width ?? 550}
      innerRadius={props.innerRadius ?? '20%'}
      data={props.payload}
      outerRadius={props.outerRadius ?? '75%'}
    >
      <PolarGrid stroke='black' radialLines />
      <PolarAngleAxis tick={PolarChartCustomLabel} dataKey='impactName' />
      <PolarRadiusAxis
        angle={90}
        orientation='left'
        tickFormatter={(value: number) => String(simplify(value))}
        tickCount={7}
        tick={{ fontSize: 12, fill: 'black' }}
      />
      {props.meta.map(({ color, dataKey }, i) => (
        <Radar
          key={i}
          isAnimationActive={false}
          dataKey={dataKey}
          stroke={color}
          strokeWidth={2}
          fill='white'
          fillOpacity={0}
          dot={(props) => {
            const isMajorOriginal = props.dataKey === 'original' && props.payload.payload.isMajorOriginal;
            const isMajorModelled = props.dataKey === 'modelled' && props.payload.payload.isMajorModelled;
            return props.dataKey === 'original' ? (
              <svg key={props.key} x={props.cx - 5} y={props.cy - 5} width={10} height={10}>
                <FontAwesomeIcon style={{ color: isMajorOriginal ? '#fbbf24' : props.stroke }} icon={solid('circle-dot')} />
              </svg>
            ) : props.dataKey === 'modelled' ? (
              <svg key={props.key} x={props.cx - 5} y={props.cy - 5} width={10} height={10}>
                <FontAwesomeIcon style={{ color: isMajorModelled ? '#fbbf24' : props.stroke }} icon={solid('circle-dot')} />
              </svg>
            ) : (
              <svg key={props.key} x={props.cx - 5} y={props.cy - 5} width={10} height={10}>
                <FontAwesomeIcon style={{ color: props.payload.payload.isMajor ? '#fbbf24' : props.stroke }} icon={solid('circle-dot')} />
              </svg>
            );
          }}
        />
      ))}
      {props.tooltipType === 'single' && (
        <Tooltip
          content={(payload: TooltipProps<ValueType & { isMajorImpact: boolean }[], NameType>) => {
            if (payload.active && payload.payload && payload.payload.length > 0) {
              return (
                <div className='flex flex-col gap-y-2 bg-white rounded-lg p-3 border shadow text-xs text-zinc-600'>
                  <div className='flex justify-between items-center gap-x-6'>
                    <div className='font-semibold text-zinc-600'>{payload.label}</div>
                    {payload.payload[0].payload.isMajorImpact && (
                      <FontAwesomeIcon className='size-5 text-amber-400' icon={solid('seal-exclamation')} />
                    )}
                  </div>
                  {payload.payload.map(({ payload }, i) => {
                    return (
                      <div key={i} className='grid grid-cols-2 gap-x-6 items-center text-zinc-500'>
                        <div>Impact</div>
                        <div className='flex items-center gap-x-1'>
                          <div className='text-zinc-900 text-sm'>{simplify(payload.impactPoints)}</div>
                          <div className='uppercase text-[10px] text-zinc-600'>impact p.</div>
                        </div>
                        <div>Contribution</div>
                        <div className='flex items-center gap-x-1'>
                          <div className='text-zinc-900 text-sm'>{roundToShort(payload.absSharePercent)}</div>
                          <div className='uppercase text-zinc-600'>%</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            }

            return null;
          }}
          isAnimationActive={false}
        />
      )}
      {props.tooltipType === 'multiple' && (
        <Tooltip
          content={(payload) => {
            if (payload.active && props.payload && props.payload.length > 0) {
              return (
                <div className='flex flex-col gap-y-4 bg-white rounded-lg p-3 border shadow text-xs text-zinc-600'>
                  <div className='flex justify-between items-center gap-x-6'>
                    <div className='font-semibold text-zinc-600'>{payload.label}</div>
                    {payload.payload![0].payload.isMajor && (
                      <FontAwesomeIcon className='size-5 text-amber-400' icon={solid('seal-exclamation')} />
                    )}
                  </div>
                  {payload.payload?.map((item, i) => {
                    const data =
                      item.dataKey === 'original'
                        ? {
                            impactPoints: item.payload.original,
                            contributionPercent: item.payload.originalAbsSharePercent,
                          }
                        : {
                            impactPoints: item.payload.modelled,
                            contributionPercent: item.payload.modelledAbsSharePercent,
                          };
                    return (
                      <div className='grid grid-cols-3 items-center gap-x-6' key={i}>
                        <div className='capitalize'>{item.name}</div>
                        <div>impact</div>
                        <div className='flex items-center gap-x-1'>
                          <div className='text-zinc-900 text-sm'>{simplify(Number(data.impactPoints))}</div>
                          <div className='uppercase text-[10px] text-zinc-600'>impact p.</div>
                        </div>

                        <div></div>
                        <div>Contribution</div>
                        <div className='flex items-center gap-x-1'>
                          <div className='text-zinc-900 text-sm'>{roundToShort(Number(data.contributionPercent))}</div>
                          <div className='uppercase text-zinc-600'>%</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            }

            return null;
          }}
          isAnimationActive={false}
        />
      )}
    </RechartsRadarChart>
  );
};
