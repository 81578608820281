import { Amount, Analysis, ConsumerView, CsvRequestParams, Entity, ProductStage, ProductType, ReportMetaData, request } from './index';

interface EntityToCompare {
  workspaceSid: string;
  baseProductId: string;
  modelId?: string;
}

export interface ComparePayload {
  products: EntityToCompare[];
}

interface ComparedProduct {
  id: string;
  parentId?: string; // in case this product is a model
  workspaceSid: string;
  productType: ProductType;
  stage: ProductStage;
  name: string;
  skuId: string;
  imageUrl: string | null;
  amount: Amount;
  servings: number | null;
  category: {
    id: Entity;
    name: 'Bacon & sausages';
  };
  conservation: {
    requirement: Entity;
  };
}

export interface ComparedProductReport {
  analysis: Analysis;
  consumerView: ConsumerView;
  impactPoints: number;
  metadata: ComparisonReportMetadata;
  product: ComparedProduct;
  scope: {
    scaledServings?: number;
    scaledProductAmount: {
      unit: {
        id: string;
        name: string;
      };
      value: number;
    };
  };
}

interface ComparisonReportMetadata extends ReportMetaData {
  functionalUnitLocation: 'consumer';
  methodologyType: {
    type: string;
    name: string;
  };
}

export const compareProducts = (payload: ComparePayload, unit: 'sku' | 'kg' | 'serving') =>
  request<{ reports: ComparedProductReport[] }>('POST', `/v3/products/compare?unit=${unit}`, { body: payload });

export const getCompareCsvOutput = <T>(name: string, payload: ComparePayload, params: CsvRequestParams<T>) =>
  request('POST', `/v3/products/compare`, { csv: name, body: payload, search: params });
