import { duotone, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import uniqBy from 'lodash/uniqBy';
import { Fragment, useState } from 'react';
import {
  Model,
  NodeReference,
  NodeType,
  PrimaryNode,
  ProductV3,
  ProductionNode,
  ProductionWarehouseNode,
  TransportNode,
} from '../../../../../../../../api';
import { getProductionFacilitiesFromAll, getProductionWarehousesFromAll, getTransportsFromAll } from '../../../../../Details/dataModel';
import { ProductionFacility } from './ProductionFacility';
import { ProductionWarehouse } from './ProductionWarehouse';

interface Props {
  product: ProductV3;
  model: Model;
}

export interface OutputPayload {
  name: string;
  amount?: {
    value: number;
    unit: {
      name: string;
    };
  };
  outputType: {
    name: string;
  };
}

export const Main = (props: Props) => {
  const [expandedWrapper, setExpandedWrapper] = useState(true);
  const [expandedFacilities, setExpandedFacilities] = useState(true);
  const [expandedWarehouses, setExpandedWarehouses] = useState(true);
  const [expandedTransportLinks, setExpandedTransportLinks] = useState(true);

  const productionFacilities = () => {
    const allProductionFacilities = [...getProductionFacilitiesFromAll([...props.product.nodes, ...props.model.nodes])];
    const productProductionFacilities = getProductionFacilitiesFromAll(props.product.nodes);
    const modelProductionFacilities = getProductionFacilitiesFromAll(props.model.nodes);

    const uniqFacilities = uniqBy(allProductionFacilities, 'id');

    return (
      <div className='flex flex-col gap-3'>
        {uniqFacilities.map((facility, index) => (
          <Fragment key={index}>
            <ProductionFacility
              product={props.product}
              model={props.model}
              productProductionFacility={productProductionFacilities.find(({ id }) => facility.id === id)}
              modelProductionFacility={modelProductionFacilities.find(({ id }) => facility.id === id)}
              allProductionFacilities={allProductionFacilities}
            />
          </Fragment>
        ))}
      </div>
    );
  };

  const productionWarehouses = () => {
    const allProductionWarehouses = [...getProductionWarehousesFromAll([...props.product.nodes, ...props.model.nodes])];

    const productProductionWarehouses = getProductionWarehousesFromAll(props.product.nodes);

    const modelProductionWarehouses = getProductionWarehousesFromAll(props.model.nodes);

    const uniqWarehouses = uniqBy(allProductionWarehouses, 'id');

    return (
      <div className='flex flex-col gap-3'>
        {uniqWarehouses.map((warehouse, index) => (
          <Fragment key={index}>
            <ProductionWarehouse
              product={props.product}
              model={props.model}
              productProductionWarehouse={productProductionWarehouses.find(({ id }) => warehouse.id === id)}
              modelProductionWarehouse={modelProductionWarehouses.find(({ id }) => warehouse.id === id)}
              allProductionWarehouses={allProductionWarehouses}
            />
          </Fragment>
        ))}
      </div>
    );
  };

  const content = (
    <div className='flex flex-col gap-6'>
      {[...getProductionFacilitiesFromAll([...props.product.nodes, ...props.model.nodes])].length > 0 && (
        <div className='flex flex-col gap-3'>
          <button onClick={() => setExpandedFacilities(!expandedFacilities)} className='flex items-center gap-2'>
            <FontAwesomeIcon className={cn('text-xs', { 'rotate-90': expandedFacilities })} icon={regular('chevron-right')} />
            <div className='h-7 aspect-square flex items-center justify-center bg-red-50 rounded-md border'>
              <FontAwesomeIcon icon={duotone('industry-windows')} />
            </div>
            <div>Production facilities</div>
          </button>
          {expandedFacilities && productionFacilities()}
        </div>
      )}
      {[...getProductionWarehousesFromAll([...props.product.nodes, ...props.model.nodes])].length > 0 && (
        <div className='flex flex-col gap-3'>
          <button onClick={() => setExpandedWarehouses(!expandedWarehouses)} className='flex items-center gap-2'>
            <FontAwesomeIcon className={cn('text-xs', { 'rotate-90': expandedWarehouses })} icon={regular('chevron-right')} />
            <div className='h-7 aspect-square flex items-center justify-center text-fuchsia-900 bg-fuchsia-50 rounded-md border'>
              <FontAwesomeIcon icon={duotone('warehouse-full')} />
            </div>
            <div>Storage</div>
          </button>
          {expandedWarehouses && productionWarehouses()}
        </div>
      )}

      {(() => {
        const productProductionNodes = [
          ...getProductionFacilitiesFromAll(props.product.nodes),
          ...getProductionWarehousesFromAll(props.product.nodes),
        ];
        const modelProductionNodes = [
          ...getProductionFacilitiesFromAll(props.model.nodes),
          ...getProductionWarehousesFromAll(props.model.nodes),
        ];

        const productDistributionNodes = [
          ...props.product.nodes.filter(({ type }) => [NodeType.Store, NodeType.FinalDestination, NodeType.Warehouse].includes(type)),
        ];
        const modelDistributionNodes = [
          ...props.model.nodes.filter(({ type }) => [NodeType.Store, NodeType.FinalDestination, NodeType.Warehouse].includes(type)),
        ];

        const productTransportLinks = getTransportsFromAll(props.product.nodes)
          .filter((transport) => productProductionNodes.find((node) => node.edges.find((edge) => edge === transport.id)))
          .map((transportLink) => ({
            ...transportLink,
            from: productProductionNodes.find((facility) => facility.edges.includes(transportLink.id)),
            to: [...productDistributionNodes, ...productProductionNodes].find(({ id }) => transportLink.edges.includes(id)),
            supplier: transportLink.supplier,
          }));

        const modelTransportLinks = getTransportsFromAll(props.model.nodes)
          .filter((transport) => modelProductionNodes.find((node) => node.edges.find((edge) => edge === transport.id)))
          .map((transportLink) => ({
            ...transportLink,
            from: modelProductionNodes.find((facility) => facility.edges.includes(transportLink.id)),
            to: [...modelDistributionNodes, ...modelProductionNodes].find(({ id }) => transportLink.edges.includes(id)),
            supplier: transportLink.supplier,
          }));

        const allTransportLinks = [...productTransportLinks, ...modelTransportLinks];

        return (
          allTransportLinks.length > 0 && (
            <div className='flex flex-col gap-3'>
              <button onClick={() => setExpandedTransportLinks(!expandedTransportLinks)} className='flex items-center gap-2'>
                <FontAwesomeIcon className={cn('text-xs', { 'rotate-90': expandedTransportLinks })} icon={regular('chevron-right')} />
                <div className='h-7 aspect-square flex items-center justify-center bg-slate-200 rounded-md border'>
                  <FontAwesomeIcon icon={duotone('truck')} />
                </div>
                <div>Transport links</div>
              </button>
              {expandedTransportLinks &&
                uniqBy(allTransportLinks, 'id').map((transportLink, index) => {
                  const productTransportLink = productTransportLinks.find(({ id }) => transportLink.id === id);
                  const modelTransportLink = modelTransportLinks.find(({ id }) => transportLink.id === id);

                  return (
                    <Fragment key={index}>
                      <RawMaterialsTransportLink
                        index={index}
                        product={props.product}
                        model={props.model}
                        productTransportLink={productTransportLink}
                        modelTransportLink={modelTransportLink}
                      />
                    </Fragment>
                  );
                })}
            </div>
          )
        );
      })()}
    </div>
  );

  return (
    <div className='border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none'>
      <button
        disabled
        onClick={() => {
          setExpandedWrapper(!expandedWrapper);
          if (expandedWrapper) {
            setExpandedFacilities(false);
            setExpandedWarehouses(false);
          }
        }}
        className='grid grid-cols-3 items-center w-full px-8 print:px-0'
      >
        <div className='grid grid-cols-3 col-span-2 items-center'>
          <div className='text-xl font-semibold py-6 group-hover:text-brand'>Production</div>
          <div></div>
          <div />
        </div>
        <FontAwesomeIcon
          className='flex justify-self-end text-gray-500 text-xl print:hidden group-hover:text-brand invisible'
          icon={expandedWrapper ? regular('circle-minus') : regular('circle-plus')}
        />
      </button>
      {expandedWrapper && <div className='px-8 pb-8 text-dark print:hidden'>{content}</div>}
      <div className='px-8 print:px-0 pb-8 hidden print:block'>{content}</div>
    </div>
  );
};

export type TransportPayload = TransportNode & {
  from: ProductionNode | ProductionWarehouseNode | undefined;
  to: PrimaryNode | undefined;
  items: NodeReference[];
};

interface RawMaterialsTransportLinksProps {
  index: number;
  product: ProductV3;
  model: Model;
  productTransportLink?: TransportPayload;
  modelTransportLink?: TransportPayload;
}

const RawMaterialsTransportLink = (props: RawMaterialsTransportLinksProps) => {
  const [expandedLink, setExpandedLink] = useState(true);
  const [expandedLegs, setExpandedLegs] = useState(true);

  const productTransportedItems =
    props.productTransportLink?.items && props.productTransportLink.items.length > 0
      ? props.productTransportLink.items.map((item) => props.product.nodes.find(({ id }) => item.id === id)?.displayName)
      : ['Final product'];

  const modelTransportedItems =
    props.modelTransportLink?.items && props.modelTransportLink.items.length > 0
      ? props.modelTransportLink.items.map((item) => props.model.nodes.find(({ id }) => item.id === id)?.displayName)
      : ['Final product'];

  return (
    <div
      className={cn('flex flex-col rounded-xl shadow bg-neutral-50 py-3 border', {
        'border-emerald-700': !props.productTransportLink && props.modelTransportLink,
        'border-red-500': props.productTransportLink && !props.modelTransportLink,
      })}
    >
      <button onClick={() => setExpandedLink(!expandedLink)} className='grid grid-cols-3 items-center'>
        <div className='flex flex-col text-sm col-span-2'>
          <div className='grid grid-cols-3 items-center gap-y-3'>
            <div className='col-span-3 flex items-center gap-x-2 self-center text-base pl-4 font-semibold'>
              <div className='h-7 aspect-square flex items-center justify-center bg-slate-200 rounded-md border'>
                <FontAwesomeIcon icon={duotone('truck')} />
              </div>
              Link {props.index + 1}
            </div>
          </div>
        </div>

        <FontAwesomeIcon
          className='flex justify-self-end text-gray-500 text-xl print:hidden group-hover:text-brand pr-3'
          icon={expandedLink ? regular('circle-minus') : regular('circle-plus')}
        />
      </button>

      {expandedLink && (
        <div className='grid grid-cols-3 text-sm px-6 pb-3'>
          <div className='grid grid-cols-3 col-span-2 gap-y-3'>
            <div className='col-span-2'></div>
            <div>
              {props.productTransportLink && !props.modelTransportLink && <div className='text-red-500'>Removed</div>}
              {!props.productTransportLink && props.modelTransportLink && <div className='text-emerald-700'>Added</div>}
            </div>

            <div></div>
            <div className='text-violet-900 text-sm font-semibold'>Original</div>
            <div className='text-violet-900 text-sm font-semibold'>Modelled</div>

            <div>From</div>
            <div>{props.productTransportLink?.from?.displayName}</div>
            <div className='flex'>
              {(() => {
                if (
                  (!props.productTransportLink && props.modelTransportLink) ||
                  (props.productTransportLink &&
                    props.modelTransportLink &&
                    props.productTransportLink.from?.displayName !== props.modelTransportLink.from?.displayName)
                ) {
                  return (
                    <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                      {props.modelTransportLink?.from?.displayName}
                    </div>
                  );
                }

                return '';
              })()}
            </div>

            <div>To</div>
            <div>{props.productTransportLink?.to?.displayName}</div>
            <div className='flex'>
              {(() => {
                if (
                  (!props.productTransportLink && props.modelTransportLink) ||
                  (props.productTransportLink &&
                    props.modelTransportLink &&
                    props.productTransportLink.to?.displayName !== props.modelTransportLink.to?.displayName)
                ) {
                  return (
                    <div className='flex self-start py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                      {props.modelTransportLink?.to?.displayName}
                    </div>
                  );
                }

                return '';
              })()}
            </div>

            <div>Transported good</div>
            <div>{props.productTransportLink && productTransportedItems.join(', ')}</div>
            <div className='flex gap-2'>
              {props.modelTransportLink &&
                modelTransportedItems
                  .filter((modelItem) => !productTransportedItems.find((productItem) => productItem === modelItem))
                  .filter(Boolean)
                  .map((item, i) => (
                    <div key={i} className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                      {item}
                    </div>
                  ))}
            </div>

            <div>Supplier</div>
            <div>{props.productTransportLink?.supplier.name}</div>
            <div className='flex'>
              {(() => {
                if (
                  (!props.productTransportLink && props.modelTransportLink) ||
                  (props.productTransportLink &&
                    props.modelTransportLink &&
                    props.productTransportLink.supplier.name !== props.modelTransportLink.supplier.name)
                ) {
                  return (
                    <div className='flex self-start text-center py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                      {props.modelTransportLink?.supplier.name}
                    </div>
                  );
                }

                return '';
              })()}
            </div>
          </div>

          {(() => {
            const productLegs = props.productTransportLink?.legs ?? [];
            const modelLegs = props.modelTransportLink?.legs ?? [];

            const uniqLegs = uniqBy([...productLegs, ...modelLegs], 'id');

            return (
              uniqLegs.length > 0 && (
                <Fragment>
                  <div className='col-span-3'>
                    <div className='flex items-center justify-between mt-3 font-semibold'>
                      <button
                        disabled={uniqLegs.length === 0}
                        onClick={() => setExpandedLegs(!expandedLegs)}
                        className='[&:not(:disabled)]:hover:text-violet-800'
                      >
                        Primary data
                      </button>
                      <FontAwesomeIcon size='sm' className={cn('', { 'rotate-180': expandedLegs })} icon={solid('chevron-down')} />
                    </div>
                  </div>
                  {(() => {
                    return (
                      expandedLegs &&
                      uniqLegs.map((leg, i) => {
                        const productLeg = productLegs.find(({ id }) => leg.id === id);
                        const modelLeg = modelLegs.find(({ id }) => leg.id === id);

                        return (
                          <div
                            key={i}
                            className={cn('flex flex-col gap-y-3 col-span-3 bg-white py-3 mt-3 rounded-lg shadow border', {
                              'border-emerald-700': !productLeg && modelLeg,
                              'border-red-500': productLeg && !modelLeg,
                            })}
                          >
                            <div className='grid grid-cols-3'>
                              <div className='col-span-2 grid grid-cols-3 gap-y-2'>
                                <div className='col-span-2 pl-3 font-semibold'>Leg {i + 1}</div>
                                <div>
                                  {!productLeg && modelLeg && <div className='text-emerald-600'>Added</div>}
                                  {productLeg && !modelLeg && <div className='text-red-500'>Removed</div>}
                                </div>

                                <div className='pl-3'>Distance (km)</div>
                                <div>{productLeg?.distance}</div>
                                <div className='flex'>
                                  {(() => {
                                    if (
                                      (modelLeg && !productLeg) ||
                                      (modelLeg && productLeg && modelLeg.distance !== productLeg.distance)
                                    ) {
                                      return (
                                        <div className='flex self-start py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                          {modelLeg.distance}
                                        </div>
                                      );
                                    }

                                    return '';
                                  })()}
                                </div>

                                <div className='pl-3'>Mode of transport</div>
                                <div>{productLeg?.mode.name}</div>
                                <div className='flex'>
                                  {(() => {
                                    if (
                                      (modelLeg && !productLeg) ||
                                      (modelLeg && productLeg && modelLeg.mode.name !== productLeg.mode.name)
                                    ) {
                                      return (
                                        <div className='flex self-start text-center py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                          {modelLeg.mode.name}
                                        </div>
                                      );
                                    }

                                    return '';
                                  })()}
                                </div>
                                <div />
                              </div>
                              <div />
                            </div>
                          </div>
                        );
                      })
                    );
                  })()}
                </Fragment>
              )
            );
          })()}
        </div>
      )}
    </div>
  );
};
