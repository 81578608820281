import { useState } from 'react';
import { TooltipV3 } from './TooltipV3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Component, MatrixComponent } from '../api';
import { simplify, roundToShort } from '../Root/Main/shared';
import { ImpactValueType } from '../Root/Main/Products/Report/Sku/Overview';
import cn from 'classnames';

interface Props {
  data: Parent[];
  totalImpact: number;
  totalPoints: number;
  unit?: string;
  showPhysicalValues?: boolean;
  productName?: string;
  selectedImpact?: { id: string; name: string; unit?: string };
  selectedImpactValueType?: ImpactValueType;
  preventModalOpen?: boolean;
}

interface InternalComponent {
  id: string;
  name: string;
  comment?: string;
  reference?: {
    id: string;
    type: string;
    amount: number;
    unit: string;
  };
  value: number;
  impactPoints: number;
  absSharePercent: number;
  isMajor: boolean;
  components: InternalComponent[];
}

interface Parent {
  name: string;
  value: number;
  impactPoints: number;
  absSharePercent: number;
  isMajor: boolean;
  isMajorImpact: boolean;
  components: InternalComponent[];
}

export const fromMatrixComponent = (components: MatrixComponent[], isPhysical?: boolean): InternalComponent[] =>
  components.map((component) => ({
    ...component,
    value: isPhysical ? component.physicalValue : component.weightedNormalisedValue,
    components: fromMatrixComponent(component.components, isPhysical),
  }));

export const fromStageImpactsComponent = (components: Component[]): InternalComponent[] =>
  components.map((component) => ({
    ...component,
    value: component.impactValue,
    absSharePercent: component.absImpactSharePercent,
    components: fromStageImpactsComponent(component.components),
  }));

export const ByStageContributionTable = (props: Props) => {
  const [expanded, setExpanded] = useState(new Array<string>());

  const handleExpandedState = (id1: string) =>
    setExpanded((current) =>
      current.includes(id1) ? current.filter((id2) => !(id2.startsWith(id1) && id2.length >= id1.length)) : [...current, id1],
    );

  const grid = `grid print:grid print:grid-cols-[1fr_1fr_30px_30px] ${
    props.showPhysicalValues ? 'grid-cols-[auto_200px_150px_50px]' : 'grid-cols-[auto_400px_100px_50px]'
  }`;

  const impactValues = (impactValue: number, impactPoints: number) => {
    return props.showPhysicalValues ? (
      <span title={impactValue.toString()}>{`${simplify(impactValue)} ${props.unit}`}</span>
    ) : (
      <div className='flex items-center gap-1 text-xs'>
        <span className='whitespace-nowrap' title={impactValue?.toString()}>
          {simplify(impactValue)}
        </span>
        <span>weighted person.year</span>
        <span className='text-zinc-500 uppercase'>or</span>
        <span title={impactPoints?.toString()}>{simplify(impactPoints)}</span>
        <span>impact points</span>
      </div>
    );
  };

  const majorImpactIcon = (invisible: boolean, dot?: boolean) => (
    <TooltipV3
      disabled={invisible}
      content={
        <div className='whitespace-nowrap rounded-lg text-xs text-zinc-800 bg-[#DACEFD] px-2 py-1 shadow-[0_0_4px_rgba(0,0,0,0.15)] print:shadow-none'>
          Most relevant
        </div>
      }
    >
      <div className='flex justify-center items-center'>
        <FontAwesomeIcon
          className={cn('text-amber-400', dot ? 'text-[6px]' : 'text-xl', { invisible: invisible })}
          icon={dot ? solid('circle') : solid('seal-exclamation')}
        />
      </div>
    </TooltipV3>
  );

  return (
    <div className='text-dark'>
      <div className='flex flex-col gap-2'>
        {props.data
          .sort((a, b) => b.absSharePercent - a.absSharePercent)
          .map((stage, i) => {
            const hasComponents = stage.components.length > 0;

            return (
              <div key={i} className={cn('border rounded-xl shadow  print:shadow-none', stage.isMajor ? 'bg-amber-50' : 'bg-zinc-50')}>
                <div
                  onClick={() => hasComponents && handleExpandedState(stage.name!)}
                  className={cn(grid, 'w-full gap-3 items-center py-3 px-4 cursor-pointer')}
                >
                  <div className='flex gap-3 items-center'>
                    <FontAwesomeIcon
                      className={cn('text-lg print:rotate-90', {
                        'rotate-90': expanded.includes(stage.name!),
                        invisible: !hasComponents,
                      })}
                      icon={light('circle-chevron-right')}
                    />
                    <div className='font-semibold text-lg'>{stage.name}</div>
                  </div>
                  {impactValues(stage.value, stage.impactPoints)}
                  <div title={stage.absSharePercent.toString()}>{roundToShort(stage.absSharePercent)}%</div>
                  {majorImpactIcon(!stage.isMajor && !stage.isMajorImpact, stage.isMajor && !stage.isMajorImpact)}
                </div>
                {hasComponents && (
                  <div className={cn('print:block', { hidden: !expanded.includes(stage.name!) })}>
                    <div className='flex flex-col gap-3 pb-2.5'>
                      {stage.components.map((component1, i1) => {
                        const { id } = component1;
                        const componentHasComponents = component1.components.length > 0;
                        return (
                          <div key={i1} className='px-4'>
                            <div className='border rounded-xl border-l-0'>
                              <div
                                className={cn('relative z-1 rounded-xl shadow-sm bg-white border-l-[3px] overflow-hidden', {
                                  'border-green-900': id === 'ingredient',
                                  'border-yellow-900': id === 'packaging_raw_material',
                                  'border-rose-900': id === 'production_process',
                                  'border-fuchsia-400': id === 'packaging_process',
                                  'border-[#701A75]': ['dist_conservation', 'conservation_consumer', 'production_conservation'].includes(
                                    id,
                                  ),
                                  'border-[#CC7979]': ['dist_loss', 'consumer_waste'].includes(id),
                                  'border-[#274E61]': id === 'preparation_step',
                                  'border-[#485167]': id === 'packaging_disposal',
                                  'border-brand': ['production_transport', 'dist_transport'].includes(id),
                                })}
                              >
                                <div
                                  onClick={() => handleExpandedState(`${stage.name}_${component1.id}`)}
                                  className={cn('w-full gap-3 items-center py-3 cursor-pointer', grid)}
                                >
                                  <div className='flex gap-3 items-center pl-4'>
                                    <FontAwesomeIcon
                                      className={cn(
                                        'text-lg print:rotate-90',
                                        {
                                          'rotate-90': expanded.includes(`${stage.name}_${component1.id}`),
                                          invisible: !componentHasComponents,
                                        },
                                        {
                                          'text-green-900': id === 'ingredient',
                                          'text-yellow-900': id === 'packaging_raw_material',
                                          'text-rose-900': id === 'production_process',
                                          'text-fuchsia-400': id === 'packaging_process',
                                          'text-[#701A75]': [
                                            'dist_conservation',
                                            'conservation_consumer',
                                            'production_conservation',
                                          ].includes(id),
                                          'text-[#CC7979]': ['dist_loss', 'consumer_waste'].includes(id),
                                          'text-[#274E61]': id === 'preparation_step',
                                          'text-[#485167]': id === 'packaging_disposal',
                                          'text-brand': ['production_transport', 'dist_transport'].includes(id),
                                        },
                                      )}
                                      icon={light('circle-chevron-right')}
                                    />
                                    <div className='font-semibold'>{component1.name}</div>
                                  </div>

                                  {impactValues(component1.value, component1.impactPoints)}
                                  <div className='flex items-center' title={component1.absSharePercent.toString()}>
                                    {roundToShort(component1.absSharePercent)}%
                                  </div>
                                  {majorImpactIcon(!component1.isMajor, component1.components.length > 0)}
                                </div>
                                <div className={cn('print:block', { hidden: !expanded.includes(`${stage.name}_${component1.id}`) })}>
                                  <div className='flex flex-col gap-0.5 border-t'>
                                    {component1.components.map((component2, i2) => (
                                      <div key={i2}>
                                        <div className={cn(grid, 'gap-3 w-full py-2')}>
                                          <div className='flex items-center gap-2 pl-8'>
                                            <div className='flex gap-x-2 items-center'>
                                              <button
                                                onClick={() => {
                                                  component2.components.length > 0 &&
                                                    handleExpandedState(`${stage.name}_${component1.id}_${component2.id}`);
                                                }}
                                                className='px-1 flex items-center gap-x-2'
                                              >
                                                <FontAwesomeIcon
                                                  className={cn('h-3 mb-0.5 aspect-square print:rotate-90', {
                                                    invisible: component2.components.length === 0,
                                                    'rotate-90': expanded.includes(`${stage.name}_${component1.id}_${component2.id}`),
                                                  })}
                                                  icon={regular('chevron-right')}
                                                />
                                                {component2.name}
                                              </button>
                                              {/* TODO: disabled since WS unknown yet and product group can leak here */}
                                              {/*{component2.reference && (
                                                <button
                                                  className='hover:scale-110'
                                                  onClick={(event) => {
                                                    event.stopPropagation();
                                                    window.open(routes.products.productOverview.production(component2.reference?.id!));
                                                  }}
                                                >
                                                  <FontAwesomeIcon
                                                    className='text-lg ml-1 rotate-45 text-violet-700'
                                                    icon={light('arrow-up')}
                                                  />
                                                </button>
                                              )}*/}
                                            </div>
                                          </div>
                                          {impactValues(component2.value, component2.impactPoints)}
                                          <div className='flex items-center' title={component2.absSharePercent.toString()}>
                                            {roundToShort(component2.absSharePercent)}%
                                          </div>
                                          {majorImpactIcon(!component2.isMajor, component2.components.length > 0)}
                                        </div>
                                        <div
                                          className={cn('print:block', {
                                            hidden: !expanded.includes(`${stage.name}_${component1.id}_${component2.id}`),
                                          })}
                                        >
                                          <div className='border-t' />
                                          {component2.components.map((component3, i3) => (
                                            <div key={i3} className={cn('flex flex-col border-b border-zinc-200')}>
                                              <div
                                                onClick={() =>
                                                  handleExpandedState(`${stage.name}_${component1.id}_${component2.id}_${component3.id}`)
                                                }
                                                className={cn(
                                                  grid,
                                                  'gap-3 w-full py-2',
                                                  { 'cursor-pointer': component3.components.length > 0 },
                                                  component3.isMajor && component3.components.length === 0 ? 'bg-amber-50' : 'bg-white',
                                                )}
                                              >
                                                <div className='flex gap-2 items-center pl-6'>
                                                  <div className='h-3 aspect-square' />
                                                  <FontAwesomeIcon
                                                    className={cn(
                                                      'h-1.5 aspect-square rotate-180 print:rotate-180',
                                                      { invisible: component3.components.length === 0 },
                                                      {
                                                        'rotate-90': !expanded.includes(
                                                          `${stage.name}_${component1.id}_${component2.id}_${component3.id}`,
                                                        ),
                                                      },
                                                    )}
                                                    icon={solid('triangle')}
                                                  />
                                                  {component3.name}
                                                  {component3.comment && (
                                                    <TooltipV3
                                                      content={
                                                        <div className='rounded-lg text-xs text-zinc-800 bg-[#DACEFD] px-2 py-1 max-w-[250px]'>
                                                          {component3.comment}
                                                        </div>
                                                      }
                                                    >
                                                      <span>
                                                        <FontAwesomeIcon className='text-brandDark' icon={regular('circle-info')} />
                                                      </span>
                                                    </TooltipV3>
                                                  )}
                                                </div>
                                                {impactValues(component3.value, component3.impactPoints)}
                                                <div className='flex items-center' title={component3.absSharePercent.toString()}>
                                                  {roundToShort(component3.absSharePercent)}%
                                                </div>
                                                {majorImpactIcon(!component3.isMajor, component3.components.length > 0)}
                                              </div>
                                              {component3.components.map((component4, i4) => (
                                                <div
                                                  key={i4}
                                                  className={cn(
                                                    {
                                                      hidden: !expanded.includes(
                                                        `${stage.name}_${component1.id}_${component2.id}_${component3.id}`,
                                                      ),
                                                    },
                                                    grid,
                                                    'gap-3 w-full py-2',
                                                    component4.isMajor ? 'bg-amber-50' : 'bg-zinc-50',
                                                    //arr3.length - 1 === i3 && 'last:shadow-[inset_0px_-7px_6px_-8px_rgba(0,0,0,0.3)]',
                                                  )}
                                                >
                                                  <div
                                                    className={cn('flex gap-2 items-center pl-10', { 'font-semibold': component4.isMajor })}
                                                  >
                                                    <div className='h-3 aspect-square' />
                                                    {component4.name}
                                                  </div>
                                                  {impactValues(component4.value, component4.impactPoints)}
                                                  <div className='flex items-center' title={component4.absSharePercent.toString()}>
                                                    {roundToShort(component4.absSharePercent)}%
                                                  </div>
                                                  {majorImpactIcon(!component4.isMajor)}
                                                </div>
                                              ))}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        <div className={cn(grid, 'w-full gap-3 items-center py-3 px-4 text-brand bg-slate-100 border rounded-xl shadow-sm')}>
          <div className='font-semibold'>Total</div>
          {impactValues(props.totalImpact, props.totalPoints)}
          <div className='text-dark'>100%</div>
        </div>
      </div>
    </div>
  );
};
