import { ReactNode, SetStateAction, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ModalV3 } from '../../../../../components/ModalV3';
import { Menu } from '../../../../../components/Menu';
import { ByStageContributionTable, fromStageImpactsComponent } from '../../../../../components/ByStageContributionTable';
import { ContextualExampleTile } from '../../../../../components/ContextualExampleTile';
import { MultiBarStackedChart } from './components/MultiBarStackedChart';
import { RadarChart } from '../../../../../components/charts/RadarChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getLifecycleIcon } from '../../../../../icons/Lifecycle';
import { ImpactValueType } from '../Sku/Overview';
import { roundToLong, roundToShort, simplify } from '../../../shared';
import {
  CsvImpactId,
  CsvType,
  getModellingCsvOutput,
  ImpactId,
  ImpactStageMatrix,
  LifecycleStageImpact,
  MatrixStage,
  ModellingReport,
  ProductStage,
  ProductType,
} from '../../../../../api';
import { useNavigate } from 'react-router';
import cn from 'classnames';
import { useAppRoutes } from '../../../../../hooks/useAppRoutes';
import { GradeBadge } from '../../../../../components/GradeBadge';
import { format } from 'date-fns';

interface Props {
  data: ModellingReport;
  selectedUnit: 'kg' | 'serving' | 'sku';
  setSelectedUnit: (value: 'kg' | 'serving' | 'sku') => void;
}

enum View {
  Graph = 'Graph view',
  Table = 'Table view',
}

export const Overview = (props: Props) => {
  const navigate = useNavigate();
  const { routes } = useAppRoutes();
  const [showImage, setShowImage] = useState(false);
  const overallImpact = { id: ImpactId.Overall, name: 'Total environmental impact' };
  const allImpactCategories: { id: ImpactId; name: string; unit?: string }[] = [
    overallImpact,
    ...props.data.calculations.product.impacts.map((impact) => ({
      id: impact.id,
      name: impact.name,
      unit: impact.unit,
    })),
  ];
  const [selectedImpact, setSelectedImpact] = useState(allImpactCategories[0]);
  const [selectedImpactValueType, setSelectedImpactValueType] = useState<ImpactValueType>(ImpactValueType.Points);
  const [impactByCategoryView, setImpactByCategoryView] = useState<View>(View.Graph);
  const [impactByLifecycleStageView, setImpactByLifecycleStageView] = useState<View>(View.Graph);

  const [waitingImpactByCategory, setWaitingImpactByCategory] = useState(false);
  const [waitingImpactByLifecycleStage, setWaitingImpactByLifecycleStage] = useState(false);
  const [waitingRelevantProcesses, setWaitingRelevantProcesses] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='relative flex flex-col justify-center -mx-6 xl:mx-0'>
      <Helmet title={`${props.data.model.title} Overview`} />
      <div className='sticky top-0 z-20 bg-white xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))]'>
        <div className='flex flex-col items-center justify-center border-b border-zinc-200'>
          <div className='flex justify-between items-center py-3 px-12 w-full xl:w-[theme(screens.xl)]'>
            <div className='flex gap-x-4 items-center truncate'>
              <button
                type='button'
                onClick={() => navigate(-1)}
                className='h-8 aspect-square flex items-center justify-center bg-[#E8EAF5] rounded-lg hover:bg-white hover:border-2 hover:border-brand'
              >
                <FontAwesomeIcon className='text-xl text-brand' icon={regular('chevron-left')} />
              </button>
              <div title={`${props.data.model.title} Overview`} className='text-xl font-semibold truncate'>
                {props.data.model.title} Overview
              </div>
            </div>

            <div className='flex gap-x-4 shrink-0'>
              <Menu
                placement='bottom-end'
                items={[
                  {
                    label: 'Per Unit',
                    onClick: () => props.setSelectedUnit('sku'),
                  },
                  {
                    label: 'Per Kg/L',
                    onClick: () => props.setSelectedUnit('kg'),
                  },
                  {
                    label: 'Per serving',
                    onClick: () => props.setSelectedUnit('serving'),
                    disabled: !props.data.product.servings,
                  },
                ]}
              >
                {() => (
                  <div className='flex flex-col'>
                    <button className='flex items-center border rounded-full px-2 h-full gap-x-2'>
                      <FontAwesomeIcon icon={light('scale-balanced')} />
                      {
                        {
                          kg: 'Per Kg/L',
                          serving: 'Per serving',
                          sku: 'Per Unit',
                        }[props.selectedUnit]
                      }
                      <FontAwesomeIcon className='text-xs' icon={light('chevron-down')} />
                    </button>
                  </div>
                )}
              </Menu>
              <NavLink
                to={routes.products.modelGraph(props.data.product.id, props.data.model.id)}
                className='whitespace-nowrap bg-slate-200 px-4 py-2 rounded-full text-sm font-semibold text-violet-950 active:scale-95'
              >
                Edit Model
              </NavLink>
              <NavLink
                to='../summary'
                className='whitespace-nowrap bg-brand px-4 py-2 rounded-full font-semibold text-sm text-white active:scale-95'
              >
                See full report
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className='xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))]'>
        <div className='divide-y'>
          <div className='flex justify-center w-full bg-slate-50'>
            <div className='flex flex-col w-full px-12 xl:px-6 xl:w-[calc(theme(screens.xl)_-_theme(spacing.12))]'>
              <div className='grid grid-cols-3 gap-x-6'>
                <div className='col-span-2 flex gap-x-10 py-6'>
                  {props.data.product.imageUrl ? (
                    <div className='flex items-center justify-items-center'>
                      <img
                        onLoad={() => setShowImage(true)}
                        className={cn('flex flex-1 border rounded-xl max-w-60 max-h-60', {
                          invisible: !showImage,
                        })}
                        src={props.data.product.imageUrl}
                        alt='product'
                      />
                    </div>
                  ) : (
                    <div className='shrink-0 size-40 flex items-center justify-items-center'>
                      <FontAwesomeIcon className='text-neutral-300 size-28 mx-auto' icon={solid('box-circle-check')} />
                    </div>
                  )}
                  <div className='w-px bg-zinc-200 h-full' />
                  <div className='flex flex-col gap-y-2'>
                    <div className='truncate text-lg text-zinc-800 font-semibold leading-tight'>{props.data.product.name}</div>
                    <div>Product ID: {props.data.product.skuId}</div>
                    <div>
                      Product type:{' '}
                      {
                        {
                          [ProductType.Final]: 'Final product',
                          [ProductType.Intermediate]: 'Intermediate product',
                          [ProductType.Internal]: 'Internal product',
                        }[props.data.product.productType]
                      }
                    </div>
                    <div>Category: {props.data.product.category?.name}</div>
                    <div>Conservation requirements: {props.data.product.conservation.requirement.name}</div>
                    <div>
                      Status:{' '}
                      {
                        {
                          [ProductStage.Development]: 'In development',
                          [ProductStage.Production]: 'In production',
                        }[props.data.product.stage]
                      }
                    </div>
                  </div>
                </div>
                <div className='flex flex-col justify-self-start self-start my-6 p-3 gap-y-4 border rounded-2xl bg-white'>
                  <div className='flex items-center gap-x-2'>
                    <FontAwesomeIcon icon={light('scale-balanced')} />
                    <div className='font-semibold text-zinc-800'>Analysed amount details</div>
                  </div>
                  <div className='flex flex-col gap-y-2'>
                    <div>
                      Analysed amount: {props.data.scope.scaledProductAmount.value} {props.data.scope.scaledProductAmount.unit.name}
                    </div>
                    {props.data.scope.scaledServings && <div>Analysed servings: {props.data.scope.scaledServings}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='relative flex flex-col bg-white mx-6 mb-20'>
        <div className='sticky top-[60px] bg-white z-[5]'>
          <div className='grid grid-cols-[256px_256px] gap-x-10 pl-8 border-b border-b-zinc-200 py-4'>
            <div className='text-zinc-800 text-xl font-semibold leading-snug'>Original product</div>
            <div className='flex items-center gap-x-2'>
              <div className='text-zinc-800 text-xl font-semibold leading-snug'>Model</div>
              <div className='flex items-center gap-x-1 p-1 pr-2 border rounded-full'>
                <div className='flex items-center text-violet-700 text-xs bg-slate-100 rounded-full px-1'>
                  {props.data.calculations.proposedChanges}
                </div>
                <div className='text-zinc-500 text-sm'>changes</div>
              </div>
            </div>
          </div>
        </div>
        <DisclosureWidget
          title={(_) => <div>Overall Impact</div>}
          body={
            <div className='grid grid-cols-[256px_256px] gap-x-10 mt-4 mb-6'>
              <div className='flex flex-col items-center gap-y-4'>
                <div title={roundToLong(props.data.calculations.product.impactPoints)} className='text-3xl'>
                  {simplify(props.data.calculations.product.impactPoints)}
                </div>
                <div className='text-sm uppercase text-zinc-500 tracking-wide'>impact points</div>
                <div className='flex rounded-full px-2 py-1 bg-neutral-100 text-zinc-500 font-semibold'>Original product</div>
              </div>
              <div className='flex flex-col items-center gap-y-4'>
                <div title={roundToLong(props.data.calculations.model.impactPoints)} className='text-3xl'>
                  {simplify(props.data.calculations.model.impactPoints)}
                </div>
                <div className='text-sm uppercase text-zinc-500 tracking-wide'>impact points</div>
                <div
                  className={cn(
                    'flex rounded-full px-2 py-1 font-semibold',
                    props.data.calculations.overallChange.value === 0
                      ? 'text-zinc-400 bg-zinc-200'
                      : props.data.calculations.overallChange.changeIsUp
                      ? 'text-red-500 bg-red-50'
                      : 'text-emerald-700 bg-emerald-50',
                  )}
                >
                  {props.data.calculations.overallChange.value === 0 ? '' : props.data.calculations.overallChange.changeIsUp ? '+' : '-'}
                  {roundToShort(props.data.calculations.overallChange.value)}%
                </div>
              </div>
            </div>
          }
        />
        <DisclosureWidget
          title={(_) => <>Most positively impactful changes</>}
          body={
            <div className='p-6'>
              {props.data.proposedChanges.length > 0 ? (
                props.data.proposedChanges.map((item, i) => (
                  <div key={i} className='flex gap-x-2 items-center'>
                    <div className='size-10 shrink-0'>{getLifecycleIcon(item.iconId)}</div>
                    <div>{item.text}</div>
                  </div>
                ))
              ) : (
                <div className='text-base uppercase text-zinc-500'>
                  {props.data.calculations.proposedChanges > 0 ? 'No Impact-reducing change' : 'No Changes made'}
                </div>
              )}
            </div>
          }
        />
        {props.selectedUnit === 'kg' && (
          <DisclosureWidget
            title={(_) => <div>Consumer rating</div>}
            body={
              <div className='grid grid-cols-[256px_256px] gap-x-10 ml-8 py-6'>
                <div className='flex'>
                  <GradeBadge grade={props.data.calculations.product.consumerView.overallGrade} />
                </div>
                <div className='flex'>
                  <GradeBadge grade={props.data.calculations.model.consumerView.overallGrade} />
                </div>
              </div>
            }
          />
        )}
        <DisclosureWidget
          title={(opened) => (
            <div className='flex w-full items-center justify-between'>
              <div>Impact by category</div>
              {opened && (
                <div className='flex items-center gap-x-2'>
                  <ViewToggle view={impactByCategoryView} setView={setImpactByCategoryView} />
                  <button
                    disabled={waitingImpactByCategory}
                    onClick={() => {
                      setWaitingImpactByCategory(true);
                      const productId = props.data.product.id;
                      const modelId = props.data.model.id;
                      const workspaceSid = props.data.product.workspaceSid;

                      getModellingCsvOutput<CsvType.impactByCategory>(
                        `${props.data.model.title} - Impact by category ${format(new Date(), 'yyyy-MM-dd')}`,
                        productId,
                        modelId,
                        {
                          reportTarget: CsvType.impactByCategory,
                        },
                        workspaceSid,
                      ).call({
                        ok: () => {
                          setWaitingImpactByCategory(false);
                        },
                        fail: () => {
                          setWaitingImpactByCategory(false);
                        },
                      });
                    }}
                    className={cn(
                      { 'cursor-wait': waitingImpactByCategory },
                      'flex gap-2 items-center px-4 text-brandDarkPurple2 font-semibold text-base',
                    )}
                  >
                    <FontAwesomeIcon icon={solid('download')} />
                    Export as CSV
                  </button>
                </div>
              )}
            </div>
          )}
          body={
            impactByCategoryView === View.Graph ? (
              <div className='flex py-6'>
                <div className='flex flex-col gap-y-4'>
                  <div className='flex items-center justify-center gap-x-4'>
                    {[
                      { label: 'Original', bg: 'bg-black' },
                      { label: 'Modelled', bg: 'bg-brand' },
                      { label: 'Most relevant', bg: 'bg-amber-400' },
                    ].map((item, i) => (
                      <div className='flex items-center gap-x-2' key={i}>
                        <div className={cn('rounded-full size-2', item.bg)} />
                        <div>{item.label}</div>
                      </div>
                    ))}
                  </div>
                  <RadarChart
                    tooltipType='multiple'
                    meta={[
                      { dataKey: 'original', color: '#000000' },
                      { dataKey: 'modelled', color: '#4f00ff' },
                    ]}
                    payload={props.data.calculations.product.impacts.map((impact) => ({
                      impactName: impact.name,
                      original: props.data.calculations.product.impacts.find((iter) => iter.id === impact.id)!.impactPoints,
                      originalAbsSharePercent: props.data.calculations.product.impacts.find((iter) => iter.id === impact.id)!
                        .contributionPercent,
                      isMajorOriginal: props.data.calculations.product.impacts.find((iter) => iter.id === impact.id)?.isMajorImpact,
                      modelled: props.data.calculations.model.impacts.find((iter) => iter.id === impact.id)!.impactPoints,
                      modelledAbsSharePercent: props.data.calculations.model.impacts.find((iter) => iter.id === impact.id)!
                        .contributionPercent,
                      isMajorModelled: props.data.calculations.model.impacts.find((iter) => iter.id === impact.id)?.isMajorImpact,
                      keyName: undefined,
                      isMajor: impact.isMajorImpact,
                    }))}
                  />
                </div>
              </div>
            ) : (
              (() => {
                const impacts = props.data.calculations.product.analysis.impactStagesMatrix.map((productImpact) => ({
                  name: productImpact.impactName,
                  product: productImpact,
                  model: props.data.calculations.model.analysis.impactStagesMatrix.find(
                    (modelImpact) => modelImpact.impactId === productImpact.impactId,
                  )!,
                }));

                return (
                  <div className='p-6'>
                    <div className='grid grid-cols-3 gap-x-10 border rounded-2xl p-3 text-sm'>
                      <div />
                      <div className='pb-3'>Original Product</div>
                      <div className='text-brand pb-3'>Modelled Product</div>

                      <div className='grid grid-cols-subgrid gap-x-10 col-span-3 py-3 border-y uppercase text-xs text-zinc-500'>
                        <div>Impact category</div>

                        <div className='grid grid-cols-2 gap-x-2'>
                          <div>Physical impact</div>
                          <div>Contribution</div>
                        </div>

                        <div className='grid grid-cols-2 gap-x-2'>
                          <div>Physical impact</div>
                          <div>Contribution</div>
                        </div>
                      </div>

                      {impacts.map((impact, i) => {
                        const renderCell = (item: ImpactStageMatrix) => (
                          <div className='grid grid-cols-2 gap-x-3 items-center'>
                            <div title={roundToLong(item.physicalValue)}>
                              {simplify(item.physicalValue)} {item.unit}
                            </div>
                            <div className='grid grid-cols-2 gap-x-2 items-center'>
                              <div>{roundToShort(item.sharePercent)}%</div>
                              <div>
                                <FontAwesomeIcon
                                  className={cn('text-xl text-amber-400', { hidden: !item.isMajorImpact })}
                                  icon={solid('seal-exclamation')}
                                />
                              </div>
                            </div>
                          </div>
                        );

                        return (
                          <div key={i} className='grid grid-cols-subgrid gap-x-10 col-span-3 py-3'>
                            <div>{impact.name}</div>
                            {renderCell(impact.product)}
                            {renderCell(impact.model)}
                          </div>
                        );
                      })}

                      <div className='grid grid-cols-subgrid gap-x-10 col-span-3 pt-3'>
                        <div className='text-brandDark font-semibold'>Total</div>
                        <div className='text-brandDark grid grid-cols-2 gap-x-3'>
                          <div></div>
                          <div>100%</div>
                        </div>
                        <div className='text-brandDark grid grid-cols-2 gap-x-3'>
                          <div></div>
                          <div>100%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })()
            )
          }
        />

        <DisclosureWidget
          title={(opened) => (
            <div className='w-full flex items-center justify-between'>
              <div>Impact by life cycle stage</div>
              {opened && (
                <div onClick={(e) => e.preventDefault()} className='flex gap-x-2 items-center'>
                  <select
                    value={selectedImpact.id}
                    onChange={({ target }) => {
                      if (target.value === ImpactId.Overall) {
                        setSelectedImpactValueType(ImpactValueType.Points);
                      }
                      setSelectedImpact(allImpactCategories.find((impact) => impact.id === target.value)!);
                    }}
                    className={cn(
                      'border border-dark rounded-full text-sm px-2 pr-3 py-1.5 focus:ring-0 hover:cursor-pointer w-56 truncate',
                      {},
                    )}
                  >
                    {allImpactCategories.map((impact) => (
                      <option key={impact.id} value={impact.id}>
                        {impact.name}
                      </option>
                    ))}
                  </select>

                  <div
                    className={cn('border rounded-full px-2 py-1.5 flex h-full', {
                      'bg-zinc-50': selectedImpact.id === overallImpact.id,
                      'border-dark': selectedImpact.id !== overallImpact.id,
                    })}
                  >
                    <button
                      type='button'
                      disabled={selectedImpact.id === overallImpact.id}
                      className='flex items-center gap-2'
                      onClick={() =>
                        setSelectedImpactValueType((current) =>
                          current === ImpactValueType.Points ? ImpactValueType.Physical : ImpactValueType.Points,
                        )
                      }
                    >
                      <div
                        className={cn(
                          'flex w-7 h-4 p-0.5 rounded-full',
                          selectedImpactValueType === 'physical' ? 'justify-end bg-brand' : 'bg-gray-300',
                        )}
                      >
                        <div className='bg-white rounded-full w-3 aspect-square' />
                      </div>
                      <div className={cn('text-sm', { 'text-zinc-400': selectedImpact.id === overallImpact.id })}>Physical impact</div>
                    </button>
                  </div>
                  <ViewToggle view={impactByLifecycleStageView} setView={setImpactByLifecycleStageView} />
                  <button
                    disabled={waitingImpactByLifecycleStage}
                    onClick={() => {
                      const productId = props.data.product.id;
                      const modelId = props.data.model.id;
                      const workspaceSid = props.data.product.workspaceSid;

                      getModellingCsvOutput<CsvType.impactByStage>(
                        `${props.data.model.title} - Impact by life cycle stage (${selectedImpact.name}) ${format(
                          new Date(),
                          'yyyy-MM-dd',
                        )}`,
                        productId,
                        modelId,
                        {
                          reportTarget: CsvType.impactByStage,
                          reportLifecycleLens: (selectedImpact.id === ImpactId.Overall ? 'totalImpact' : selectedImpact.id) as CsvImpactId,
                        },
                        workspaceSid,
                      ).call({
                        ok: () => {
                          setWaitingImpactByLifecycleStage(false);
                        },
                        fail: () => {
                          setWaitingImpactByLifecycleStage(false);
                        },
                      });
                    }}
                    className='flex gap-2 items-center px-4 text-brandDarkPurple2 font-semibold text-base'
                  >
                    <FontAwesomeIcon icon={solid('download')} />
                    Export as CSV
                  </button>
                </div>
              )}
            </div>
          )}
          body={
            <div className='p-6'>
              {impactByLifecycleStageView === View.Graph ? (
                <ImpactByLifeCycleStage
                  data={props.data}
                  selectedImpact={selectedImpact}
                  selectedImpactValueType={selectedImpactValueType}
                  setSelectedImpact={setSelectedImpact}
                  setSelectedImpactValueType={setSelectedImpactValueType}
                />
              ) : (
                (() => {
                  const stages = props.data.calculations.product.analysis.lifecycleStageImpacts.map((productStage) => ({
                    name: productStage.name,
                    product: productStage,
                    model: props.data.calculations.model.analysis.lifecycleStageImpacts.find(
                      (modelStage) => modelStage.id === productStage.id,
                    )!,
                  }));

                  return (
                    <div className='grid grid-cols-[1fr_2fr_2fr] gap-x-10 rounded-2xl border p-3'>
                      <div />
                      <div className='pb-3'>Original Product</div>
                      <div className='text-brand pb-3'>Modelled Product</div>

                      <div className='grid grid-cols-subgrid gap-x-10 col-span-3 py-3 border-y uppercase text-xs text-zinc-500'>
                        <div>Life cycle stage</div>

                        <div className='grid grid-cols-2 gap-x-2'>
                          <div>Final ENV. impact</div>
                          <div>Contribution</div>
                        </div>

                        <div className='grid grid-cols-2 gap-x-2'>
                          <div>Final ENV. impact</div>
                          <div>Contribution</div>
                        </div>
                      </div>

                      {selectedImpact.id === ImpactId.Overall ? (
                        <>
                          {stages.map((stage, i) => {
                            const renderCell = (item: LifecycleStageImpact) => (
                              <div className='grid grid-cols-2 gap-x-3 items-center'>
                                <div title={roundToLong(item.impactPoints)}>{simplify(item.impactPoints)} Impact points</div>
                                <div className='grid grid-cols-2 gap-x-2 items-center'>
                                  <div title={roundToLong(item.absImpactSharePercent)}>{roundToShort(item.absImpactSharePercent)}%</div>
                                  <div>
                                    <FontAwesomeIcon
                                      className={cn('text-xl text-amber-400', { hidden: !item.isMajorImpact })}
                                      icon={solid('seal-exclamation')}
                                    />
                                  </div>
                                </div>
                              </div>
                            );

                            return (
                              <div key={i} className='grid grid-cols-subgrid gap-x-10 col-span-3 py-3'>
                                <div>{stage.name}</div>
                                {renderCell(stage.product)}
                                {renderCell(stage.model)}
                              </div>
                            );
                          })}

                          <div className='grid grid-cols-subgrid gap-x-10 col-span-3 pt-3'>
                            <div className='text-brandDark font-semibold'>Total</div>
                            <div className='text-brandDark grid grid-cols-2 gap-x-3'>
                              <div>{simplify(props.data.calculations.product.impactPoints)} Impact points</div>
                              <div>100%</div>
                            </div>
                            <div className='text-brandDark grid grid-cols-2 gap-x-3'>
                              <div>{simplify(props.data.calculations.model.impactPoints)} Impact points</div>
                              <div>100%</div>
                            </div>
                          </div>
                        </>
                      ) : (
                        (() => {
                          const productStagesOfSelectedImpact = props.data.calculations.product.analysis.impactStagesMatrix.find(
                            ({ impactId }) => selectedImpact.id === impactId,
                          );

                          const stages = productStagesOfSelectedImpact?.stages.map((productStage) => ({
                            name: productStage.name,
                            product: productStage,
                            model: props.data.calculations.model.analysis.impactStagesMatrix
                              .find(({ impactId }) => selectedImpact.id === impactId)!
                              .stages.find(({ name }) => name === productStage.name)!,
                          }))!;

                          return (
                            <div className='grid grid-cols-subgrid gap-x-10 col-span-3'>
                              {stages.map((stage, i) => {
                                const renderValue = (item: MatrixStage) => {
                                  if (selectedImpactValueType === ImpactValueType.Points) {
                                    return (
                                      <div title={roundToShort(item.impactPoints).toString()}>
                                        {simplify(item.impactPoints)} Impact points
                                      </div>
                                    );
                                  } else {
                                    const selectedImpactUnit = allImpactCategories.find((impact) => impact.id === selectedImpact.id)!.unit;
                                    return (
                                      <div title={roundToShort(item.impactPoints).toString()}>
                                        {simplify(item.physicalValue)} {selectedImpactUnit}
                                      </div>
                                    );
                                  }
                                };

                                const renderCell = (item: MatrixStage) => {
                                  return (
                                    <div className='grid grid-cols-2 gap-x-3 items-center'>
                                      <div>{renderValue(item)}</div>
                                      <div className='grid grid-cols-2 gap-x-2 items-center'>
                                        <div title={roundToLong(item.absSharePercent).toString()}>
                                          {roundToShort(item.absSharePercent)}%
                                        </div>
                                        <div>
                                          <FontAwesomeIcon
                                            className={cn('text-xl text-amber-400', { hidden: !item.isMajorStage })}
                                            icon={solid('seal-exclamation')}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                };

                                return (
                                  <div key={i} className='grid grid-cols-subgrid gap-x-10 col-span-3 py-3'>
                                    <div>{stage.name}</div>
                                    <div>{renderCell(stage.product)}</div>
                                    <div>{renderCell(stage.model)}</div>
                                  </div>
                                );
                              })}

                              <div className='grid grid-cols-subgrid gap-x-10 col-span-3 pt-3'>
                                <div className='text-brandDark font-semibold'>Total</div>
                                <div className='text-brandDark grid grid-cols-2 gap-x-3'>
                                  <div>
                                    {simplify(
                                      stages.reduce(
                                        (acc, item) =>
                                          acc +
                                          (selectedImpactValueType === ImpactValueType.Points
                                            ? item.product.impactPoints
                                            : item.product.physicalValue),
                                        0,
                                      ),
                                    )}{' '}
                                    {selectedImpactValueType === ImpactValueType.Points ? 'Impact points' : selectedImpact.unit}
                                  </div>
                                  <div>100%</div>
                                </div>
                                <div className='text-brandDark grid grid-cols-2 gap-x-3'>
                                  <div>
                                    {simplify(
                                      stages.reduce(
                                        (acc, item) =>
                                          acc +
                                          (selectedImpactValueType === ImpactValueType.Points
                                            ? item.model.impactPoints
                                            : item.model.physicalValue),
                                        0,
                                      ),
                                    )}{' '}
                                    {selectedImpactValueType === ImpactValueType.Points ? 'Impact points' : selectedImpact.unit}
                                  </div>
                                  <div>100%</div>
                                </div>
                              </div>
                            </div>
                          );
                        })()
                      )}
                    </div>
                  );
                })()
              )}
            </div>
          }
        />
        <DisclosureWidget
          title={(opened) => (
            <div className='flex w-full items-center justify-between'>
              <div>Most relevant processes</div>
              {opened && (
                <button
                  disabled={waitingRelevantProcesses}
                  onClick={() => {
                    const productId = props.data.product.id;
                    const modelId = props.data.model.id;
                    const workspaceSid = props.data.product.workspaceSid;

                    getModellingCsvOutput<CsvType.relevantProcesses>(
                      `${props.data.model.title} - Most relevant processes ${format(new Date(), 'yyyy-MM-dd')}`,
                      productId,
                      modelId,
                      { reportTarget: CsvType.relevantProcesses },
                      workspaceSid,
                    ).call({
                      ok: () => {
                        setWaitingRelevantProcesses(false);
                      },
                      fail: () => {
                        setWaitingRelevantProcesses(false);
                      },
                    });
                  }}
                  className={cn(
                    { 'cursor-wait': waitingRelevantProcesses },
                    'flex gap-2 items-center px-4 text-brandDarkPurple2 font-semibold text-base',
                  )}
                >
                  <FontAwesomeIcon icon={solid('download')} />
                  Export as CSV
                </button>
              )}
            </div>
          )}
          body={
            <div className='grid grid-cols-[256px_256px] gap-x-10 pl-8 p-6'>
              {[
                {
                  entity: props.data.calculations.product,
                  name: props.data.product.name,
                },
                {
                  entity: props.data.calculations.model,
                  name: props.data.model.title,
                },
              ].map(({ name, entity }, i) => (
                <div className='flex flex-col gap-y-6' key={i}>
                  <ModalV3
                    size='wide'
                    title={<div>{name}</div>}
                    body={
                      <div className='-mt-6'>
                        <ByStageContributionTable
                          productName={name}
                          selectedImpact={overallImpact}
                          data={entity.analysis.lifecycleStageImpacts.map((impact) => ({
                            ...impact,
                            value: impact.impactValue,
                            absSharePercent: impact.absImpactSharePercent,
                            components: fromStageImpactsComponent(impact.components),
                          }))}
                          totalImpact={entity.overallImpact}
                          totalPoints={entity.impactPoints}
                        />
                      </div>
                    }
                  >
                    <button className='flex self-center text-violet-950 text-sm font-semibold rounded-lg shadow bg-slate-200 py-1.5 px-4'>
                      Full contribution table
                    </button>
                  </ModalV3>
                  {entity.analysis.majorProcesses.map((process, i) => (
                    <div className='h-28 flex flex-col gap-y-1' key={i}>
                      {(() => {
                        const arrayFromString = process.name.split(' - ');

                        return (
                          <div className='flex flex-col gap-y-1 truncate'>
                            <div className='truncate font-semibold'>{arrayFromString.splice(0, 1)}</div>
                            <div title={arrayFromString.join(' > ')} className='truncate'>
                              {'> '}
                              {arrayFromString.join(' > ')}
                            </div>
                          </div>
                        );
                      })()}
                      <div className='flex items-center gap-x-2'>
                        <div className='text-xl'>{simplify(process.sharePercent)}%</div>
                        <div>of total contribution</div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          }
        />
        {props.data.interpretation.contextualExamples.length > 0 && (
          <DisclosureWidget
            title={(_) => <>Differences in physical impact</>}
            body={
              <div className='flex flex-col gap-y-6 pl-8 p-6'>
                <div className='text-zinc-600'>
                  To help you compare these two products with more realistic numbers the difference in impact should be considered on more
                  than a single product. As such, the results below show the difference for 100 units of these two products.
                </div>
                <div className='grid grid-cols-3 gap-6'>
                  {props.data.interpretation.contextualExamples.map((contextualExample, i) => (
                    <ContextualExampleTile key={i} contextualExample={contextualExample} />
                  ))}
                </div>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};

interface ImpactByLifeCycleStageProps {
  data: ModellingReport;
  selectedImpact: { id: ImpactId; name: string; unit?: string };
  selectedImpactValueType: ImpactValueType;
  setSelectedImpact: (value: SetStateAction<{ id: ImpactId; name: string; unit?: string }>) => void;
  setSelectedImpactValueType: (value: SetStateAction<ImpactValueType>) => void;
}

const ImpactByLifeCycleStage = (props: ImpactByLifeCycleStageProps) => {
  return (
    <div className='flex flex-col xl:w-1/2 border rounded-2xl pt-3'>
      <div className='flex items-center self-center gap-x-4'>
        {props.data.calculations.product.analysis.lifecycleStageImpacts.map((stage) => (
          <div key={stage.id} className='flex items-center gap-x-1'>
            <div
              style={{
                backgroundColor: stage.bgColor,
              }}
              className={cn('size-2 rounded-full')}
            />
            <div>{stage.name}</div>
          </div>
        ))}
      </div>
      <div className='-mx-6 pt-6 pl-0'>
        <MultiBarStackedChart
          model
          data={[props.data.calculations.product.analysis, props.data.calculations.model.analysis]}
          selectedImpact={props.selectedImpact}
          type={props.selectedImpactValueType}
        />
      </div>
    </div>
  );
};

const DisclosureWidget = ({ title, body }: { title: (opened: boolean) => ReactNode; body: ReactNode }) => {
  const [opened, setOpened] = useState(false);

  return (
    <details open className='-mx-6 px-6 xl:-mx-3 xl:px-3' onToggle={(e) => setOpened((e.target as any).open)}>
      <summary className='cursor-pointer py-4 marker:content-[""] border-b border-zinc-200'>
        <div className='flex items-center gap-x-4 cursor-pointer text-xl text-zinc-900'>
          <FontAwesomeIcon className={cn('text-xl flex self-center', { 'rotate-180': opened })} icon={light('chevron-down')} />
          {title(opened)}
        </div>
      </summary>
      {body}
    </details>
  );
};

const ViewToggle = ({ view, setView }: { view: View; setView: (v: SetStateAction<View>) => void }) => {
  return (
    <select
      value={view}
      onChange={({ target }) => setView(target.value as View)}
      className={cn('border border-dark rounded-full text-sm px-2 pr-3 py-1.5 focus:ring-0 hover:cursor-pointer', {})}
    >
      <option value={View.Graph}>{View.Graph}</option>
      <option value={View.Table}>{View.Table}</option>
    </select>
  );
};
