export const simplify = (v?: number) => {
  if (v === undefined || v === null) return '';
  if (v === 0) return v;

  const valueFormats = [
    {
      letter: 'M',
      limit: 1e9,
    },
    {
      letter: 'B',
      limit: 1e12,
    },
    {
      letter: 'T',
      limit: 1e15,
    },
  ];
  const formatValue = (value: number) => {
    const format = valueFormats.find((format) => value < format.limit)!;

    if (!format) {
      return value.toExponential(2);
    }
    value = (value * 1000) / format.limit;
    value = Math.round(value * 100) / 100; // keep two decimal number, only if needed

    return value + format.letter;
  };

  if (Math.abs(v) > 999_999) {
    return formatValue(v);
  } else if (Math.abs(v) > 999) {
    return v
      .toFixed(0)
      .toLocaleString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else if (Math.abs(v) >= 10) {
    return parseFloat(v.toFixed(1));
  } else if (Math.abs(v) < 0.01) {
    return v.toExponential(3);
  } else {
    return parseFloat(v.toFixed(3));
  }
};

// Test
/*console.log(
  [
    0.000000000001, 0.00000354435, 0.000030005, 0.0001, 0.009, 0.01, 0.012, 0.09, 0.1, 0.123, 0, 1, 10, 10.1, 12, 18, 23.5, 40.234798, 99,
    99.9, 100, 100.1001, 999.01, 999, 1000, 1001, 1001.1123, 555.555, 10_000, 999999, 1_000_000, 1_500_000, 10_000_000, 99_999_999,
    100_000_000.123, 100_000_000.999, 1_000_000_000, 1_300_000_999, 1_500_000_000, 1_530_000_000, 1_999_999_999,
  ].map((v) => `${v} -> ${formatNumberDisplayNotation(v)}`),
);*/

export const roundToLong = (v: number) => {
  if (v === 0) {
    return '0';
  } else if (v < 0.1 && v > 0.0000001) {
    const normalizedDecimal = () => {
      let sign = +v < 0 ? '-' : '',
        toStr = v.toString();
      if (!/e/i.test(toStr)) {
        return v;
      }
      let [lead, decimal, pow] = v
        .toString()
        .replace(/^-/, '')
        .replace(/^([0-9]+)(e.*)/, '$1.$2')
        .split(/[e.]/);
      return +pow < 0
        ? sign + '0.' + '0'.repeat(Math.max(Math.abs(Number(pow)) - 1 || 0, 0)) + lead + decimal
        : sign +
            lead +
            (+pow >= decimal.length
              ? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
              : decimal.slice(0, +pow) + '.' + decimal.slice(+pow));
    };

    return normalizedDecimal().toString().substring(0, 9);
  } else if (v <= 0.0000001 && v > 0) {
    return '≤0.0000001';
  } else {
    return Number(v.toFixed(5)).toString();
  }
};

export const roundToShort = (v: number) => {
  if (v < 0) {
    return Number(v.toFixed(2));
  }
  if (v === 100) {
    return 100;
  } else if (v === 0) {
    return 0;
  } else if (Number(v.toFixed(1)) === 0) {
    return Number(v.toFixed(2));
  } else {
    return Number(v.toFixed(1));
  }
};
