import { ImpactTracking, TargetType, Targets } from '../../../api';
import {
  addDays,
  addMonths,
  addYears,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
  endOfYear,
  isAfter,
  isSameMonth,
  isSameYear,
  isWithinInterval,
  startOfYear,
  subDays,
  subMonths,
} from 'date-fns';
import isBefore from 'date-fns/isBefore';
import { convertUTCDateToLocal } from '../../datepicker/utils';
import endOfMonth from 'date-fns/endOfMonth';
/*import startOfMonth from 'date-fns/startOfMonth';*/

export enum BaselineTimeframeType {
  Calendar = 'calendar_year',
  Tax = 'tax_year',
}

const shiftDate = (date: Date) => addMonths(date, 3);

const isSameDate = (date1: Date, date2: Date) => isSameYear(date1, date2) && isSameMonth(date1, date2);

const convert = (impact: ImpactTracking) => ({
  reportsTimeFrames: () => {
    if (impact.baseline) {
      const baselineImpactData = {
        name: impact.baseline.name,
        points: impact.baseline.totalImpact.points,
        physical: impact.baseline.totalImpact.physical,
        unit: impact.unit,
      };

      const baselineReport = [
        {
          ...baselineImpactData,
          date: new Date(impact.baseline.start.date),
        },
        {
          ...baselineImpactData,
          date: new Date(impact.baseline.end.date),
        },
      ];

      const historicalReports = impact.reports.map((report) => {
        const impactData = {
          name: report.name,
          points: report.totalImpact.points,
          physical: report.totalImpact.physical,
          unit: impact.unit,
        };

        return [
          {
            ...impactData,
            date: new Date(report.start.date),
          },
          {
            ...impactData,
            date: new Date(report.end.date),
          },
        ];
      });

      return [baselineReport, ...historicalReports];
    }
  },

  missedDataFrames: () => {
    if (impact.baseline && impact.reports.length > 0) {
      const missedDataBetweenBaselineAndFirstReport = [
        {
          name: impact.baseline.name,
          date: addDays(convertUTCDateToLocal(impact.baseline.end.date), 1),
          points: impact.baseline.totalImpact.points,
          physical: impact.baseline.totalImpact.physical,
          unit: impact.unit,
        },
        {
          name: impact.reports[0].name,
          date: subDays(convertUTCDateToLocal(impact.reports[0].start.date), 1),
          points: impact.reports[0].totalImpact.points,
          physical: impact.reports[0].totalImpact.physical,
          unit: impact.unit,
        },
      ];

      const missedDataRanges = impact.reports
        .map((report, i, arr) => {
          const nextReport = arr[i + 1];
          if (nextReport) {
            return [
              {
                name: report.name,
                date: addDays(convertUTCDateToLocal(report.end.date), 1),
                points: report.totalImpact.points,
                physical: report.totalImpact.physical,
                unit: impact.unit,
              },
              {
                name: nextReport.name,
                date: subDays(convertUTCDateToLocal(nextReport.start.date), 1),
                points: nextReport.totalImpact.points,
                physical: nextReport.totalImpact.physical,
                unit: impact.unit,
              },
            ];
          } else {
            return [
              {
                name: report.name,
                date: addDays(convertUTCDateToLocal(report.end.date), 1),
                points: report.totalImpact.points,
                physical: report.totalImpact.physical,
                unit: impact.unit,
              },
              {
                name: report.name,
                date: subMonths(endOfMonth(new Date()), 1),
                points: report.totalImpact.points,
                physical: report.totalImpact.physical,
                unit: impact.unit,
              },
            ];
          }
        })
        .filter((link) => link);

      return [missedDataBetweenBaselineAndFirstReport, ...missedDataRanges];
    }
  },

  getBaselineData: () => {
    if (impact.baseline) {
      return {
        name: impact.baseline.name,
        points: impact.baseline.totalImpact.points,
        physical: impact.baseline.totalImpact.physical,
        unit: impact.unit,
      };
    }
  },

  getForecastData: () => {
    if (impact.forecast) {
      return {
        name: impact.forecast.name,
        points: impact.forecast.totalImpact.points,
        physical: impact.forecast.totalImpact.physical,
        unit: impact.unit,
      };
    }
  },

  getTargetData: () => {
    if (impact.target) {
      return {
        estimate: impact.target.estimate,
        unit: impact.unit,
        type: impact.target.type,
        start: {
          ...impact.target.start,
          date: new Date(impact.target.start.date),
        },
        end: {
          ...impact.target.end,
          date: new Date(impact.target.end.date),
        },
        targetBadge: impact.targetBadge,
      };
    }
  },

  getTargetHint: () => impact.targetHint,

  getPredictionData: () => {
    if (impact.estimate) {
      return impact.estimate;
    }
  },

  getAxisValues: () => {
    return {
      xAxis: {
        startDate: new Date(impact.xAxis.startDate),
        endDate: new Date(impact.xAxis.endDate),
      },
      yAxis: {
        points: impact.yAxisMaxValue.points,
        physical: impact.yAxisMaxValue.physical,
      },
    };
  },

  getTargetDateBadge: () => impact.targetDateBadge,
});

export const getTrackingData = (selectedImpact: ImpactTracking, targets?: Targets) => {
  const data = {
    chartTimeframe: {
      startDate: convert(selectedImpact).getAxisValues().xAxis.startDate,
      endDate: (() => {
        if (
          selectedImpact.target?.type === TargetType.LONG_TERM &&
          selectedImpact.target?.end.date &&
          isAfter(new Date(selectedImpact.target.end.date), addYears(new Date(), 8))
        ) {
          return endOfYear(new Date(selectedImpact.target.end.date));
        } else {
          return addYears(endOfYear(new Date()), 8);
        }
      })(),
    },
    colors: {
      primary: 'bg-purple-400',
      secondary: 'bg-purple-200',
      empty: 'bg-gray-100',
      hex: '#e9d5ff',
    },
    reports: convert(selectedImpact)
      .reportsTimeFrames()!
      .map((item) => ({
        start: item[0].date,
        end: item[1].date,
        points: item[0].points,
        physical: item[0].physical,
      })),
    missedDataFrames: convert(selectedImpact as ImpactTracking).missedDataFrames()
      ? convert(selectedImpact)
          .missedDataFrames()!
          .map((item) => {
            return {
              start: item![0].date,
              end: item![1].date,
              points: item![0].points,
              physical: item![0].physical,
            };
          })
      : [],
    estimation: convert(selectedImpact as ImpactTracking).getPredictionData(),
    target: convert(selectedImpact as ImpactTracking).getTargetData(),
    timeframe: convert(selectedImpact as ImpactTracking).getAxisValues(),
    forecast: convert(selectedImpact as ImpactTracking).getForecastData(),
  };

  const getReportDataOfCurrentYear = (
    report: { start: Date; end: Date; points: number; physical?: number },
    year: Date,
    type: BaselineTimeframeType,
  ) => {
    if (type === BaselineTimeframeType.Tax) {
      if (isSameDate(report.start, shiftDate(year)) && isSameDate(report.end, shiftDate(endOfYear(year)))) {
        // for baseline
        return {
          ...report,
        };
      } else if (
        // report in timeframe of 31 march - 1 april
        isWithinInterval(report.start, { start: shiftDate(year), end: shiftDate(endOfYear(year)) }) &&
        isWithinInterval(report.end, { start: shiftDate(year), end: shiftDate(endOfYear(year)) })
      ) {
        const totalMonths = eachMonthOfInterval({
          start: report.start,
          end: report.end,
        });

        const monthsInCurrentYear = totalMonths.filter((month) =>
          isWithinInterval(month, { start: shiftDate(year), end: shiftDate(endOfYear(year)) }),
        );

        return {
          start: report.start,
          end: report.end,
          points: (report.points / totalMonths.length) * monthsInCurrentYear.length,
          physical: report.physical ? (report.physical / totalMonths.length) * monthsInCurrentYear.length : undefined,
        };
      } else if (
        // report start in previos year and ends in current year, set start date to 1 april
        !isWithinInterval(report.start, { start: shiftDate(year), end: shiftDate(endOfYear(year)) }) &&
        isWithinInterval(report.end, { start: shiftDate(year), end: shiftDate(endOfYear(year)) })
      ) {
        const totalMonths = eachMonthOfInterval({
          start: report.start,
          end: report.end,
        });

        const monthsInCurrentYear = totalMonths.filter((month) =>
          isWithinInterval(month, { start: shiftDate(year), end: shiftDate(endOfYear(year)) }),
        );
        return {
          start: shiftDate(year),
          end: report.end,
          points: (report.points / totalMonths.length) * monthsInCurrentYear.length,
          physical: report.physical ? (report.physical / totalMonths.length) * monthsInCurrentYear.length : undefined,
        };
      } else if (
        // report start in current year and ends in next year, set end date to 31 march
        isWithinInterval(report.start, { start: shiftDate(year), end: shiftDate(endOfYear(year)) }) &&
        !isWithinInterval(report.end, { start: shiftDate(year), end: shiftDate(endOfYear(year)) })
      ) {
        const totalMonths = eachMonthOfInterval({
          start: report.start,
          end: report.end,
        });

        const monthsInCurrentYear = totalMonths.filter((month) =>
          isWithinInterval(month, { start: shiftDate(year), end: shiftDate(endOfYear(year)) }),
        );

        return {
          start: report.start,
          end: shiftDate(endOfYear(year)),
          points: (report.points / totalMonths.length) * monthsInCurrentYear.length,
          physical: report.physical ? (report.physical / totalMonths.length) * monthsInCurrentYear.length : undefined,
        };
      } else if (isBefore(report.start, shiftDate(year)) && isAfter(report.end, shiftDate(endOfYear(year)))) {
        const totalMonths = eachMonthOfInterval({
          start: report.start,
          end: report.end,
        });

        const monthsInCurrentYear = totalMonths.filter((month) =>
          isWithinInterval(month, { start: shiftDate(year), end: shiftDate(endOfYear(year)) }),
        );

        return {
          start: shiftDate(year),
          end: shiftDate(endOfYear(year)),
          points: (report.points / totalMonths.length) * monthsInCurrentYear.length,
          physical: report.physical ? (report.physical / totalMonths.length) * monthsInCurrentYear.length : undefined,
        };
      }
      return;
    } else if (type === BaselineTimeframeType.Calendar) {
      if (isSameYear(report.start, year) && isSameYear(report.end, year)) {
        return {
          ...report,
        };
      } else if (isSameYear(report.start, year) && !isSameYear(report.end, year)) {
        const totalMonths = eachMonthOfInterval({
          start: report.start,
          end: report.end,
        });
        const monthsInCurrentYear = totalMonths.filter((month) => isSameYear(month, year));

        return {
          start: report.start,
          end: endOfYear(report.start),
          points: (report.points / totalMonths.length) * monthsInCurrentYear.length,
          physical: report.physical ? (report.physical / totalMonths.length) * monthsInCurrentYear.length : undefined,
        };
      } else if (!isSameYear(report.start, year) && isSameYear(report.end, year)) {
        const totalMonths = eachMonthOfInterval({
          start: report.start,
          end: report.end,
        });
        const monthsInCurrentYear = totalMonths.filter((month) => isSameYear(month, year));

        return {
          start: startOfYear(report.end),
          end: report.end,
          points: (report.points / totalMonths.length) * monthsInCurrentYear.length,
          physical: report.physical ? (report.physical / totalMonths.length) * monthsInCurrentYear.length : undefined,
        };
      } else if (
        !isSameYear(report.start, year) &&
        !isSameYear(report.end, year) &&
        isBefore(report.start, report.end) &&
        isWithinInterval(year, { start: report.start, end: report.end })
      ) {
        return {
          start: startOfYear(year),
          end: endOfYear(year),
          points: (report.points / eachMonthOfInterval({ start: report.start, end: report.end }).length) * 12,
          physical: undefined,
        };
      }

      return;
    }

    return;
  };

  const getGapDataOfCurrentYear = (
    gap: { start: Date; end: Date; points: number; physical?: number },
    year: Date,
    reports: { start: Date; end: Date; points: number; physical?: number }[],
    report: { start: Date; end: Date; points: number; physical?: number },
    type: BaselineTimeframeType,
  ) => {
    if (isAfter(gap.start, gap.end)) {
      // no missed data between reports.
      return;
    }

    if (type === BaselineTimeframeType.Calendar) {
      if (!isSameYear(gap.start, year) && isSameYear(gap.end, year)) {
        const latestExistedReports = reports.filter(
          (prevReport) =>
            (isSameYear(prevReport.end, report.end) && isBefore(prevReport.start, report.start)) ||
            isSameMonth(prevReport.start, report.start),
        );

        const totalPoints = latestExistedReports.reduce((acc, item) => acc + item.points, 0);
        const totalPhysical = latestExistedReports.reduce((acc, item) => acc + (item.physical ?? 0), 0);

        const totalMonths = latestExistedReports.reduce((acc, item) => {
          return acc + eachMonthOfInterval({ start: item.start, end: addMonths(item.end, 0) }).length;
        }, 0);

        const totalMonthsOfGapInCurrentYear = eachMonthOfInterval({
          start: startOfYear(year),
          end: gap.end,
        });

        const totalGapPointsOfCurrentYear = (totalPoints / totalMonths) * totalMonthsOfGapInCurrentYear.length;
        const totalGapPhysicalOfCurrentYear = (totalPhysical / totalMonths) * totalMonthsOfGapInCurrentYear.length;

        return {
          start: startOfYear(year),
          end: gap.end,
          points: totalGapPointsOfCurrentYear,
          physical: totalGapPhysicalOfCurrentYear,
        };
      }

      if (isSameYear(gap.start, year) && !isSameYear(gap.end, year)) {
        const latestExistedReports = reports.filter(
          (prevReport) =>
            (isSameYear(prevReport.start, report.start) && isBefore(prevReport.start, report.start)) ||
            isSameMonth(prevReport.start, report.start),
        );

        const totalPoints = latestExistedReports.reduce((acc, item) => acc + item.points, 0);
        const totalPhysical = latestExistedReports.reduce((acc, item) => acc + (item.physical ?? 0), 0);

        const totalMonths = latestExistedReports.reduce((acc, item) => {
          return acc + eachMonthOfInterval({ start: item.start, end: addMonths(item.end, 0) }).length;
        }, 0);

        const totalMonthsOfGapInCurrentYear = eachMonthOfInterval({
          start: gap.start,
          end: endOfYear(year),
        });

        const totalGapPointsOfCurrentYear = (totalPoints / totalMonths) * totalMonthsOfGapInCurrentYear.length;
        const totalGapPhysicalOfCurrentYear = (totalPhysical / totalMonths) * totalMonthsOfGapInCurrentYear.length;

        return {
          start: gap.start,
          end: endOfYear(gap.start),
          points: totalGapPointsOfCurrentYear,
          physical: totalGapPhysicalOfCurrentYear,
        };
      }

      if (isSameYear(gap.start, year) && isSameYear(gap.end, year)) {
        const latestExistedReports = reports.filter(
          (prevReport) =>
            (isSameYear(prevReport.start, report.start) && isBefore(prevReport.start, report.start)) ||
            isSameMonth(prevReport.start, report.start),
        );

        const totalPoints = latestExistedReports.reduce((acc, item) => acc + item.points, 0);
        const totalPhysical = latestExistedReports.reduce((acc, item) => acc + (item.physical ?? 0), 0);

        const totalMonths = latestExistedReports.reduce((acc, item) => {
          return acc + eachMonthOfInterval({ start: item.start, end: item.end }).length;
        }, 0);

        const totalMonthsOfGapInCurrentYear = eachMonthOfInterval({
          start: gap.start,
          end: gap.end,
        });

        const totalGapPointsOfCurrentYear = (totalPoints / totalMonths) * totalMonthsOfGapInCurrentYear.length;
        const totalGapPhysicalOfCurrentYear = (totalPhysical / totalMonths) * totalMonthsOfGapInCurrentYear.length;

        return {
          start: gap.start,
          end: gap.end,
          points: totalGapPointsOfCurrentYear,
          physical: totalGapPhysicalOfCurrentYear,
        };
      }

      if (!isSameYear(gap.start, year) && !isSameYear(gap.end, year) && isWithinInterval(year, { start: gap.start, end: gap.end })) {
        const latestExistedReports = (() => {
          if (isSameYear(gap.end, endOfYear(new Date()))) {
            return reports.filter((r) => isSameYear(r.start, report.start));
          } else {
            return reports.filter((r) => isSameYear(r.start, report.end));
          }
        })();

        const totalPoints = latestExistedReports.reduce((acc, item) => acc + item.points, 0);
        const totalPhysical = latestExistedReports.reduce((acc, item) => acc + (item.physical ?? 0), 0);

        const totalMonths = latestExistedReports.reduce((acc, item) => {
          return acc + eachMonthOfInterval({ start: item.start, end: addMonths(item.end, 0) }).length;
        }, 0);

        const totalMonthsOfGap = eachMonthOfInterval({
          start: startOfYear(year),
          end: endOfYear(year),
        });

        const totalGapPoints = (totalPoints / totalMonths) * totalMonthsOfGap.length;
        const totalGapPhysical = (totalPhysical / totalMonths) * totalMonthsOfGap.length;

        return {
          start: startOfYear(year),
          end: endOfYear(year),
          points: totalGapPoints,
          physical: totalGapPhysical,
        };
      }
    } else if (type === BaselineTimeframeType.Tax) {
      const yearStart = shiftDate(startOfYear(year));
      const yearEnd = shiftDate(endOfYear(year));

      if (
        // if gap cover full tax year timeframe
        isWithinInterval(yearStart, { start: gap.start, end: gap.end }) &&
        (isWithinInterval(yearEnd, { start: gap.start, end: gap.end }) || isSameDate(yearEnd, gap.end))
      ) {
        const latestReportEndDate = reports.find((r) => isSameDate(r.end, subDays(gap.start, 1)))!;
        const latestReports = reports.filter((r) => isSameDate(r.end, latestReportEndDate.end));

        const totalPoints = latestReports.reduce((acc, item) => acc + item.points, 0);
        const totalPhysical = latestReports.reduce((acc, item) => acc + (item.physical ?? 0), 0);

        const totalMonths = latestReports.reduce((acc, item) => {
          return acc + eachMonthOfInterval({ start: item.start, end: item.end }).length;
        }, 0);

        const totalMonthsOfGap = eachMonthOfInterval({
          start: yearStart,
          end: yearEnd,
        });

        const totalGapPoints = (totalPoints / totalMonths) * totalMonthsOfGap.length;
        const totalGapPhysical = (totalPhysical / totalMonths) * totalMonthsOfGap.length;

        return {
          start: yearStart,
          end: yearEnd,
          points: totalGapPoints,
          physical: totalGapPhysical,
        };
      } else if (
        // if gap start in current year and ends in future
        isWithinInterval(gap.start, {
          start: yearStart,
          end: yearEnd,
        }) &&
        !isWithinInterval(gap.end, {
          start: yearStart,
          end: yearEnd,
        })
      ) {
        const latestReportEndDate = reports.find((r) => isSameDate(r.end, subDays(gap.start, 1)))!;
        const latestReports = reports.filter((r) => isSameDate(r.end, latestReportEndDate.end));

        const totalPoints = latestReports.reduce((acc, item) => acc + item.points, 0);
        const totalPhysical = latestReports.reduce((acc, item) => acc + (item.physical ?? 0), 0);

        const totalMonths = latestReports.reduce((acc, item) => {
          return acc + eachMonthOfInterval({ start: item.start, end: item.end }).length;
        }, 0);

        const totalMonthsOfGap = eachMonthOfInterval({
          start: gap.start,
          end: yearEnd,
        });

        const totalGapPoints = (totalPoints / totalMonths) * totalMonthsOfGap.length;
        const totalGapPhysical = (totalPhysical / totalMonths) * totalMonthsOfGap.length;

        return {
          start: gap.start,
          end: yearEnd,
          points: totalGapPoints,
          physical: totalGapPhysical,
        };
      } else if (
        // if gap start in previous year and ends in current year
        (!isWithinInterval(gap.start, {
          start: yearStart,
          end: yearEnd,
        }) &&
          isWithinInterval(gap.end, {
            start: yearStart,
            end: yearEnd,
          })) ||
        isSameDate(gap.end, yearEnd)
      ) {
        const latestReportEndDate = reports.find((r) => isSameDate(r.end, subDays(gap.start, 1)))!;
        const latestReports = reports.filter((r) => isSameDate(r.end, latestReportEndDate.end));

        const totalPoints = latestReports.reduce((acc, item) => acc + item.points, 0);
        const totalPhysical = latestReports.reduce((acc, item) => acc + (item.physical ?? 0), 0);

        const totalMonths = latestReports.reduce((acc, item) => {
          return acc + eachMonthOfInterval({ start: item.start, end: item.end }).length;
        }, 0);

        const totalMonthsOfGap = eachMonthOfInterval({
          start: yearStart,
          end: gap.end,
        });

        const totalGapPoints = (totalPoints / totalMonths) * totalMonthsOfGap.length;
        const totalGapPhysical = (totalPhysical / totalMonths) * totalMonthsOfGap.length;

        return {
          start: yearStart,
          end: gap.end,
          points: totalGapPoints,
          physical: totalGapPhysical,
        };
      } else if (
        // if gap start in current tax year and ends in current tax year
        isWithinInterval(gap.start, {
          start: yearStart,
          end: yearEnd,
        }) &&
        (isWithinInterval(gap.end, {
          start: yearStart,
          end: yearEnd,
        }) ||
          isSameDate(gap.end, yearEnd))
      ) {
        const latestReportEndDate = reports.find((r) => isSameDate(r.end, subDays(gap.start, 1)))!;
        const latestReports = reports.filter((r) => isSameDate(r.end, latestReportEndDate.end));

        const totalPoints = latestReports.reduce((acc, item) => acc + item.points, 0);
        const totalPhysical = latestReports.reduce((acc, item) => acc + (item.physical ?? 0), 0);

        const totalMonths = latestReports.reduce((acc, item) => {
          return acc + eachMonthOfInterval({ start: item.start, end: item.end }).length;
        }, 0);

        const totalMonthsOfGap = eachMonthOfInterval({
          start: gap.start,
          end: gap.end,
        });

        const totalGapPoints = (totalPoints / totalMonths) * totalMonthsOfGap.length;
        const totalGapPhysical = (totalPhysical / totalMonths) * totalMonthsOfGap.length;

        return {
          start: gap.start,
          end: gap.end,
          points: totalGapPoints,
          physical: totalGapPhysical,
        };
      }
    }
  };

  // calendar year starts from 1 january, tax year starts from 1 april
  const baselineTimeframeType =
    selectedImpact.baseline && new Date(selectedImpact.baseline?.start.date!).getMonth() === 0
      ? BaselineTimeframeType.Calendar
      : BaselineTimeframeType.Tax;

  return {
    start: data.chartTimeframe.startDate,
    end: data.chartTimeframe.endDate,
    type: baselineTimeframeType,
    data: eachYearOfInterval({
      start: new Date(data.chartTimeframe.startDate),
      end: new Date(data.chartTimeframe.endDate),
    }).map((iterYear) => {
      const currentYearTotalDays = eachDayOfInterval({ start: iterYear, end: endOfYear(iterYear) }).length;

      return {
        year: iterYear,
        dataset: (() => {
          const reports = data.reports
            .map((report, i) => ({
              ...getReportDataOfCurrentYear(report, iterYear, baselineTimeframeType),
              colors: data.colors,
              type: i === 0 ? 'baseline' : 'report',
            }))
            .filter(({ start, end }) => start && end && isAfter(end, start));

          const gaps = data.missedDataFrames
            .map((gap, i) => ({
              ...getGapDataOfCurrentYear(gap, iterYear, data.reports, data.reports[i], baselineTimeframeType),
              colors: data.colors,
              type: 'gap',
            }))
            .filter(({ start, end }) => start && end && isAfter(end, start));

          /*const estimations = (() => {
            if (baselineTimeframeType === BaselineTimeframeType.Calendar) {
              if (!data.estimation || isBefore(iterYear, startOfYear(new Date()))) return undefined;

              const actualAndAssumedData = [
                ...data.reports.map((report) => getReportDataOfCurrentYear(report, new Date(), baselineTimeframeType)).filter(Boolean),
                ...data.missedDataFrames
                  .map((gap, i) => getGapDataOfCurrentYear(gap, new Date(), data.reports, data.reports[i], baselineTimeframeType))
                  .filter(Boolean),
              ].reduce(
                (acc, item) => ({
                  points: acc.points + (item?.points ?? 0),
                  physical: acc.physical + (item?.physical ?? 0),
                }),
                {
                  points: 0,
                  physical: 0,
                },
              );

              const actualAndAssumedDataTimeframe = eachMonthOfInterval({
                start: startOfYear(new Date()),
                end: subMonths(startOfMonth(new Date()), 1),
              }).length;

              const fullEstimationTimeframe = eachMonthOfInterval({
                start: startOfYear(new Date()),
                end: data.chartTimeframe.endDate,
              });

              const estimationTimeframe = eachMonthOfInterval({
                start: isSameYear(new Date(), iterYear) ? startOfMonth(new Date()) : startOfYear(iterYear),
                end: endOfYear(iterYear),
              }).length;

              const baselineMonthlyImpact = {
                points: data.reports[0].points / 12,
                physical: data.reports[0].physical ? data.reports[0].physical / 12 : undefined,
              };

              const monthlyActualAndAssumedDataImpact = {
                points: actualAndAssumedData.points / actualAndAssumedDataTimeframe,
                physical: actualAndAssumedData.physical / actualAndAssumedDataTimeframe,
              };

              const estimationTrend = {
                points: (baselineMonthlyImpact.points - monthlyActualAndAssumedDataImpact.points) / baselineMonthlyImpact.points,
                physical: baselineMonthlyImpact.physical
                  ? (baselineMonthlyImpact.physical - monthlyActualAndAssumedDataImpact.physical) / baselineMonthlyImpact.physical
                  : undefined,
              };

              const index = fullEstimationTimeframe.findIndex((date) => isSameYear(date, iterYear));

              const currentYearEstimation = {
                points:
                  monthlyActualAndAssumedDataImpact.points * estimationTimeframe -
                  monthlyActualAndAssumedDataImpact.points * estimationTrend.points * (index + 1),
                physical: monthlyActualAndAssumedDataImpact.physical
                  ? monthlyActualAndAssumedDataImpact.physical * estimationTimeframe -
                    monthlyActualAndAssumedDataImpact.physical * estimationTrend.physical! * (index + 1)
                  : undefined,
              };

              return {
                start: isSameYear(new Date(), iterYear) ? startOfMonth(new Date()) : startOfYear(iterYear),
                end: endOfYear(iterYear),
                points: currentYearEstimation.points,
                physical: currentYearEstimation.physical,
                colors: data.colors,
                type: 'estimation',
              };
            } else if (baselineTimeframeType === BaselineTimeframeType.Tax) {
              if (!data.estimation || isBefore(iterYear, startOfYear(new Date()))) return undefined;

              const actualAndAssumedData = [
                ...data.reports
                  .map((report) => getReportDataOfCurrentYear(report, startOfYear(new Date()), baselineTimeframeType))
                  .filter(Boolean),
                ...data.missedDataFrames
                  .map((gap, i) =>
                    getGapDataOfCurrentYear(gap, startOfYear(new Date()), data.reports, data.reports[i], baselineTimeframeType),
                  )
                  .filter(Boolean),
              ].reduce(
                (acc, item) => ({
                  points: acc.points + (item?.points ?? 0),
                  physical: acc.physical + (item?.physical ?? 0),
                }),
                {
                  points: 0,
                  physical: 0,
                },
              );

              const actualAndAssumedDataTimeframe = eachMonthOfInterval({
                start: shiftDate(startOfYear(new Date())),
                end: subMonths(startOfMonth(new Date()), 1),
              }).length;

              const fullEstimationTimeframe = eachMonthOfInterval({
                start: shiftDate(startOfYear(new Date())),
                end: shiftDate(data.chartTimeframe.endDate),
              });

              const estimationTimeframe = eachMonthOfInterval({
                start: isSameYear(iterYear, new Date()) ? startOfMonth(new Date()) : shiftDate(startOfYear(iterYear)),
                end: shiftDate(endOfYear(iterYear)),
              }).length;

              const baselineMonthlyImpact = {
                points: data.reports[0].points / 12,
                physical: data.reports[0].physical ? data.reports[0].physical / 12 : undefined,
              };

              const monthlyActualAndAssumedDataImpact = {
                points: actualAndAssumedData.points / actualAndAssumedDataTimeframe,
                physical: actualAndAssumedData.physical / actualAndAssumedDataTimeframe,
              };

              const estimationTrend = {
                points: (baselineMonthlyImpact.points - monthlyActualAndAssumedDataImpact.points) / baselineMonthlyImpact.points,
                physical: baselineMonthlyImpact.physical
                  ? (baselineMonthlyImpact.physical - monthlyActualAndAssumedDataImpact.physical) / baselineMonthlyImpact.physical
                  : undefined,
              };

              const index = fullEstimationTimeframe.findIndex((date) => isSameYear(date, iterYear));

              const currentYearEstimation = {
                points:
                  monthlyActualAndAssumedDataImpact.points * estimationTimeframe -
                  monthlyActualAndAssumedDataImpact.points * estimationTrend.points * (index + 1),
                physical: monthlyActualAndAssumedDataImpact.physical
                  ? monthlyActualAndAssumedDataImpact.physical * estimationTimeframe -
                    monthlyActualAndAssumedDataImpact.physical * estimationTrend.physical! * (index + 1)
                  : undefined,
              };

              return {
                start: isSameYear(iterYear, new Date()) ? startOfMonth(addMonths(new Date(), 1)) : startOfYear(iterYear),
                end: shiftDate(endOfYear(iterYear)),
                points: currentYearEstimation.points,
                physical: currentYearEstimation.physical,
                colors: data.colors,
                type: 'estimation',
              };
            }
          })();*/

          return [...reports, ...gaps /*...(() => (estimations ? [estimations] : []))()*/];
        })(),
        targetData: (() => {
          if (!data.target || !targets) return;

          const target = data.target;

          if (target) {
            const targetRange = eachYearOfInterval({
              start: target.start.date,
              end: target.end.date,
            });

            const stepPoints =
              (target.end.dailyImpact.points * currentYearTotalDays - target.start.dailyImpact.points * currentYearTotalDays) /
              targetRange.length;
            const stepPercentage = targets.targetImpacts.find((item) => item.id === selectedImpact.id)?.reduction;

            const stepPhysical =
              (target.end.dailyImpact.physical! * currentYearTotalDays - target.start.dailyImpact.physical! * currentYearTotalDays) /
              targetRange.length;

            const result = eachYearOfInterval({ start: target.start.date, end: new Date(data.chartTimeframe.endDate) }).map(
              (targetYear, i) => ({
                date: targetYear,
                type: target.type,
                points: target.start.dailyImpact.points * currentYearTotalDays + stepPoints * i,
                physical: target.start.dailyImpact.physical! * currentYearTotalDays + stepPhysical * i,
              }),
            );

            const current = result.find((item) => isSameYear(item.date, iterYear));

            if (current) {
              return {
                ...target,
                stepPoints,
                stepPhysical,
                stepPercentage,
                start: startOfYear(iterYear),
                end: endOfYear(iterYear),
                points: current.points,
                physical: current.physical,
                colors: data.colors,
                type: current.type, // long_term || year_over_year
                additionalData: targets.targetImpacts.find((item) => item.id === selectedImpact.id),
              };
            }
          }
        })(),

        forecastData: data.forecast,
      };
    }),
  };
};
