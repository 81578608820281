import { SetStateAction } from 'react';
import { SingleSelect } from './SingleSelect';
import { ViewToggle } from './ViewToggle';
import { ImpactValueType } from '../Root/Main/Products/Report/Sku/Overview';
import { ImpactId } from '../api';

interface Props {
  theme: 'light' | 'dark';
  impacts: { id: ImpactId; name: string }[];
  selectedImpact: { id: ImpactId; name: string };
  setSelectedImpact: (v: { id: ImpactId; name: string }) => void;
  selectedImpactValueType: ImpactValueType;
  setSelectedImpactValueType: (v: SetStateAction<ImpactValueType>) => void;
  disabled?: boolean;
}

export const ImpactSelect = (props: Props) => {
  return (
    <div className='flex flex-col gap-6 print:hidden'>
      <div className='self-center w-80'>
        <SingleSelect
          disabled={props.disabled}
          options={props.impacts}
          value={{
            value: props.selectedImpact.id,
            label: props.selectedImpact.name,
          }}
          setSelectedId={(v) => {
            if (v === ImpactId.Overall) {
              props.setSelectedImpactValueType(ImpactValueType.Points);
            }
            props.setSelectedImpact(props.impacts.find((impact) => impact.id === v)!);
          }}
          selectedId={props.selectedImpact.id}
        />
      </div>

      <div className='flex justify-center items-center'>
        <ViewToggle
          theme={props.theme}
          button1={{
            label: 'Final environmental impact',
            active: props.selectedImpactValueType === ImpactValueType.Points,
            disabled: props.selectedImpact.id === ImpactId.Overall,
          }}
          button2={{
            label: 'Physical Impact',
            active: props.selectedImpactValueType === ImpactValueType.Physical,
            disabled: props.selectedImpact.id === ImpactId.Overall,
          }}
          toggleView={() =>
            props.setSelectedImpactValueType((current) =>
              current === ImpactValueType.Points ? ImpactValueType.Physical : ImpactValueType.Points,
            )
          }
        />
      </div>
    </div>
  );
};
