import { StrictMode, useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { createRoutesFromElements, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import {
  ErrorPayload,
  hasAuthentication,
  hasAuthenticationBypassParameters,
  NotFoundReason,
  onApiError,
  onForbidden,
  onNotFound,
  onUnauthorized,
  removeApiErrorListeners,
  removeNotFoundListeners,
  removeUnauthorizedListeners,
} from '../api';
import { ForgotPassword } from './ForgotPassword';
import { Login } from './Login';
import { Main } from './Main';
import { ResetPassword } from './ResetPassword';

export const Root = () =>
  process.env.RELAXED ? (
    <HelmetProvider>
      <Content />
    </HelmetProvider>
  ) : (
    <StrictMode>
      <HelmetProvider>
        <Content />
      </HelmetProvider>
    </StrictMode>
  );

const Content = () => {
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'prod') {
      const script1 = document.createElement('script');
      script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-LTRNVC401H';
      script1.defer = true;
      document.head.appendChild(script1);
      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'G-LTRNVC401H');
      `;
      document.head.appendChild(script2);
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'prod') {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:3493658,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `;
      document.head.appendChild(script);
    }
  }, []);

  /* TODO: errorElement disabled */
  const router = createBrowserRouter(createRoutesFromElements(<Route path='*' element={<Wrapper />} errorElement={<></>} />));

  return (
    <div className='antialiased text-body flex justify-center'>
      <RouterProvider router={router} />
    </div>
  );
};

const Wrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hasError, setHasError] = useState<ErrorPayload<NotFoundReason>>();

  useEffect(() => {
    if (!hasAuthentication()) {
      if (location.pathname.startsWith('/workspaces')) {
        localStorage.setItem('redirect', location.pathname);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  useEffect(() => {
    onUnauthorized(() => {
      navigate('/');
    });

    return removeUnauthorizedListeners;
  }, [navigate]);

  useEffect(() => {
    onApiError((payload) => {
      alert(payload ? JSON.stringify(payload, null, 2) : 'Oops something went wrong…');
    });

    return removeApiErrorListeners;
  }, []);

  useEffect(() => {
    onNotFound(async (payload) => {
      const error = await payload;
      setHasError(error);
    });

    return removeNotFoundListeners;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onForbidden(async (payload) => {
      const error = await payload;
      setHasError(error);
    });
  }, []);

  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/trial' element={<Login trial />} />;
      <Route path='/forgot-password' element={<ForgotPassword />} />;
      <Route path='/reset/:email/:code' element={<ResetPassword />} />;
      <Route
        path='*'
        element={
          hasAuthentication() || hasAuthenticationBypassParameters() ? (
            <Main error={hasError} clearError={() => setHasError(undefined)} />
          ) : (
            <Navigate to='/login' replace />
          )
        }
      />
    </Routes>
  );
};
